import { IFetchAdapter, IRequestConfig } from "@sdl-marketing/objects/interfaces/IFetchAdapter";
import axios, { AxiosInstance, AxiosRequestConfig, AxiosResponse } from "axios";
import { ResultAsync, err, ok } from "neverthrow";

export class FetchAdapter implements IFetchAdapter {
  private httpsClient: AxiosInstance;

  constructor(baseURL: string) {
    this.httpsClient = axios.create({
      baseURL,
      headers: {
        "Content-Type": "application/json",
        Accept: "*/*",
      },
    });
  }

  get<T>(url: string, config?: IRequestConfig): ResultAsync<T, Error> {
    return ResultAsync.fromPromise(
      this.httpsClient.get<T>(url, config).then(response => response.data),
      (error: any) => new Error(error.message || "Network error")
    );
  }

  post<T>(url: string, data: any, config?: IRequestConfig): ResultAsync<T, Error> {
    return ResultAsync.fromPromise(
      this.httpsClient.post<T>(url, data, config).then(response => response.data),
      (error: any) => new Error(error.message || "Network error")
    );
  }

  put<T>(url: string, data: any, config?: IRequestConfig): ResultAsync<T, Error> {
    return ResultAsync.fromPromise(
      this.httpsClient.put<T>(url, data, config).then(response => response.data),
      (error: any) => new Error(error.message || "Network error")
    );
  }

  delete<T>(url: string, config?: IRequestConfig): ResultAsync<T, Error> {
    return ResultAsync.fromPromise(
      this.httpsClient.delete<T>(url, config).then(response => response.data),
      (error: any) => new Error(error.message || "Network error")
    );
  }
}

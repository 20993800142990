
import { ApiGateway } from "@sdl-marketing/implementations/ApiGateway";
import { IApiGateway } from "@sdl-marketing/objects/interfaces/IApiGateway";
import React, { useContext, createContext, FC, PropsWithChildren } from "react";


interface IProviderValues {
  apiGateway: IApiGateway;
}

const StoreContext = createContext<IProviderValues>({} as IProviderValues);
const apiGateway = new ApiGateway();

export const StoreProvider: FC<PropsWithChildren> = ({ children }) => {
  return (
    <StoreContext.Provider value={{ apiGateway }}>
      {children}
    </StoreContext.Provider>
  );
};

export const useStoreContext = () => useContext(StoreContext);

import React from "react";
import { createRoot } from "react-dom/client";
import App from "./App";
import theme from "./theme/theme";
import { TextStyleProvider } from "./contexts/TextStyleContext";
import { LayoutProvider } from "./contexts/LayoutContext";
import { StoreProvider } from "./contexts/StoreContext";
import { AnalyticsProvider } from "./contexts/AnalyticsContext";
import { ThemeProvider } from "@mui/material/styles";
import { BrowserRouter } from "react-router-dom";
import { EntriesProvider } from "./contexts/EntriesContext";

const app = (
  <BrowserRouter>
    <EntriesProvider>
      <TextStyleProvider>
        <ThemeProvider theme={theme}>
          <StoreProvider>
            <AnalyticsProvider>
              <LayoutProvider>
                <App />
              </LayoutProvider>
            </AnalyticsProvider>
          </StoreProvider>
        </ThemeProvider>
      </TextStyleProvider>
    </EntriesProvider>
  </BrowserRouter>
);

createRoot(document.getElementById("app")).render(app);

function inDev() {
  return process.env.NODE_ENV == "development";
}

if (!inDev()) {
  if ("serviceWorker" in navigator) {
    window.addEventListener("load", () => {
      navigator.serviceWorker
        .register("/service-worker.js")
        .then((registration) => {
          console.log("SW registered: ", registration);
        })
        .catch((registrationError) => {
          console.log("SW registration failed: ", registrationError);
        });
    });
  }
}

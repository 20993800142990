import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import theme from "@sdl-marketing/theme/theme";
import React, { FC } from "react";

export interface ITab {
  label: string;
}

export interface ITabsProps {
  tabs: ITab[];
  activeIndex: number;
  onTabClick: (index: number) => void;
}

export const Tabs: FC<ITabsProps> = ({
  tabs,
  activeIndex,
  onTabClick,
}: ITabsProps) => {
  return (
    <Box
      margin="auto"
      display="flex"
      width="fit-content"
      bgcolor="rgba(110, 98, 166, 0.08)"
      p={0.5}
      borderRadius={4}
      gap={0.5}
    >
      {tabs.map((tab, index) => {
        return (
          <Box
            margin="auto"
            display="flex"
            width={{ xs: 114, sm: 168 }}
            height={{ xs: 32, sm: 44 }}
            key={index}
            alignItems="center"
            justifyContent="center"
            bgcolor={
              activeIndex === index
                ? theme.palette.customColors.WHITE
                : "transparent"
            }
            borderRadius={3}
            p={0.5}
            onClick={() => onTabClick(index)}
            sx={{
              cursor: "pointer",
              boxShadow:
                activeIndex === index
                  ? "0px 12px 12px 0px rgba(110, 98, 166, 0.16)"
                  : "none",
              transition: "all 0.2s",
            }}
          >
            <Typography
              sx={{
                fontFamily: "DM Sans",
                fontSize: { xs: 14, sm: 16 },
                fontWeight: 700,
                color:
                  activeIndex === index
                    ? theme.palette.customColors.DARKPURPLE500
                    : theme.palette.customColors.MAINPURPLE700,
                opacity: activeIndex === index ? 1 : 0.44,
              }}
            >
              {tab.label}
            </Typography>
          </Box>
        );
      })}
    </Box>
  );
};

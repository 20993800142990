import { textAlign } from "@mui/system";
import { baseFontProperties } from "./_config";

export type TextStyle = React.CSSProperties;

export interface TextStyles {
  [key: string]: TextStyle;
}

export interface TextStyleContextType {
  texts: typeof defaultTextStyles;
  truncateString: (str: string, num: number) => string;
}

export const defaultTextStyles = {
  title_montserrat_96_white: {
    color: baseFontProperties.color.white,
    fontSize: baseFontProperties.sizes.size_96,
    fontWeight: baseFontProperties.weight.medium,
    fontFamily: baseFontProperties.family.montserrat,
    lineHeight: baseFontProperties.lineHeight.size_112,
  },
};

export const stateOfFarcasterSeventhSectionTextStyles = {
  head_title: {
    color: baseFontProperties.color.black,
    fontSize: {
      xl: baseFontProperties.sizes.size_72,
      lg: baseFontProperties.sizes.size_72,
      md: baseFontProperties.sizes.size_72,
      sm: baseFontProperties.sizes.size_64,
      xs: baseFontProperties.sizes.size_64,
    },
    fontWeight: baseFontProperties.weight.bold,
    fontFamily: baseFontProperties.family.dm,
    lineHeight: {
      xl: baseFontProperties.lineHeight.size_72,
      lg: baseFontProperties.lineHeight.size_72,
      md: baseFontProperties.lineHeight.size_72,
      sm: baseFontProperties.lineHeight.size_64,
      xs: baseFontProperties.lineHeight.size_64,
    },
  },
};

export const stateOfFarcasterSixthSectionTextStyles = {
  title: {
    color: baseFontProperties.color.black,
    fontSize: {
      xl: baseFontProperties.sizes.size_48,
      lg: baseFontProperties.sizes.size_48,
      md: baseFontProperties.sizes.size_48,
      sm: baseFontProperties.sizes.size_40,
      xs: baseFontProperties.sizes.size_40,
    },
    fontWeight: baseFontProperties.weight.bold,
    fontFamily: baseFontProperties.family.dm,
    lineHeight: {
      xl: baseFontProperties.lineHeight.size_48,
      lg: baseFontProperties.lineHeight.size_48,
      md: baseFontProperties.lineHeight.size_48,
      sm: baseFontProperties.lineHeight.size_40,
      xs: baseFontProperties.lineHeight.size_40,
    },
    textAlign: "center",
  },
  sub_text: {
    color: baseFontProperties.color.black,
    fontSize: {
      xl: baseFontProperties.sizes.size_20,
      lg: baseFontProperties.sizes.size_20,
      md: baseFontProperties.sizes.size_20,
      sm: baseFontProperties.sizes.size_12,
      xs: baseFontProperties.sizes.size_12,
    },
    fontWeight: baseFontProperties.weight.normal,
    fontFamily: baseFontProperties.family.dm,
    lineHeight: {
      xl: baseFontProperties.lineHeight.size_32,
      lg: baseFontProperties.lineHeight.size_32,
      md: baseFontProperties.lineHeight.size_32,
      sm: baseFontProperties.lineHeight.size_24,
      xs: baseFontProperties.lineHeight.size_24,
    },
    textAlign: "center",
  },
  sub_text_bold: {
    color: baseFontProperties.color.black,
    fontSize: {
      xl: baseFontProperties.sizes.size_20,
      lg: baseFontProperties.sizes.size_20,
      md: baseFontProperties.sizes.size_20,
      sm: baseFontProperties.sizes.size_12,
      xs: baseFontProperties.sizes.size_12,
    },
    fontWeight: baseFontProperties.weight.bold,
    fontFamily: baseFontProperties.family.dm,
    lineHeight: {
      xl: baseFontProperties.lineHeight.size_20,
      lg: baseFontProperties.lineHeight.size_20,
      md: baseFontProperties.lineHeight.size_20,
      sm: baseFontProperties.lineHeight.size_12,
      xs: baseFontProperties.lineHeight.size_12,
    },
    textAlign: "center",
  },
};

export const stateOfFarcasterFifthSectionTextStyles = {
  right_card_title: {
    color: baseFontProperties.color.white,
    fontSize: {
      xl: baseFontProperties.sizes.size_48,
      lg: baseFontProperties.sizes.size_48,
      md: baseFontProperties.sizes.size_48,
      sm: baseFontProperties.sizes.size_40,
      xs: baseFontProperties.sizes.size_40,
    },
    fontWeight: baseFontProperties.weight.bold,
    fontFamily: baseFontProperties.family.dm,
    lineHeight: {
      xl: baseFontProperties.lineHeight.size_48,
      lg: baseFontProperties.lineHeight.size_48,
      md: baseFontProperties.lineHeight.size_48,
      sm: baseFontProperties.lineHeight.size_40,
      xs: baseFontProperties.lineHeight.size_40,
    },
  },
  right_card_description: {
    color: baseFontProperties.color.white,
    fontSize: {
      xl: baseFontProperties.sizes.size_20,
      lg: baseFontProperties.sizes.size_20,
      md: baseFontProperties.sizes.size_20,
      sm: baseFontProperties.sizes.size_16,
      xs: baseFontProperties.sizes.size_16,
    },
    fontWeight: baseFontProperties.weight.normal,
    fontFamily: baseFontProperties.family.dm,
    lineHeight: {
      xl: baseFontProperties.lineHeight.size_28,
      lg: baseFontProperties.lineHeight.size_28,
      md: baseFontProperties.lineHeight.size_28,
      sm: baseFontProperties.lineHeight.size_20,
      xs: baseFontProperties.lineHeight.size_20,
    },
  },
};

export const stateOfFarcasterThirdSectionTextStyles = {
  title: {
    color: baseFontProperties.color.black,
    fontSize: {
      xl: baseFontProperties.sizes.size_72,
      lg: baseFontProperties.sizes.size_72,
      md: baseFontProperties.sizes.size_72,
      sm: baseFontProperties.sizes.size_64,
      xs: baseFontProperties.sizes.size_64,
    },
    fontWeight: baseFontProperties.weight.bold,
    fontFamily: baseFontProperties.family.dm,
    lineHeight: {
      xl: baseFontProperties.lineHeight.size_72,
      lg: baseFontProperties.lineHeight.size_72,
      md: baseFontProperties.lineHeight.size_72,
      sm: baseFontProperties.lineHeight.size_64,
      xs: baseFontProperties.lineHeight.size_64,
    },
    textAlign: "center",
  },
  text: {
    color: baseFontProperties.color.black,
    fontSize: {
      xl: baseFontProperties.sizes.size_20,
      lg: baseFontProperties.sizes.size_20,
      md: baseFontProperties.sizes.size_20,
      sm: baseFontProperties.sizes.size_16,
      xs: baseFontProperties.sizes.size_16,
    },
    fontWeight: baseFontProperties.weight.normal,
    fontFamily: baseFontProperties.family.dm,
    lineHeight: {
      xl: baseFontProperties.lineHeight.size_28,
      lg: baseFontProperties.lineHeight.size_28,
      md: baseFontProperties.lineHeight.size_28,
      sm: baseFontProperties.lineHeight.size_20,
      xs: baseFontProperties.lineHeight.size_20,
    },
    textAlign: "center",
  },
};

export const stateOfFarcasterSecondSectionTextStyles = {
  money_text: {
    color: baseFontProperties.color.black,
    fontSize: {
      xl: baseFontProperties.sizes.size_72,
      lg: baseFontProperties.sizes.size_72,
      md: baseFontProperties.sizes.size_72,
      sm: baseFontProperties.sizes.size_64,
      xs: baseFontProperties.sizes.size_64,
    },
    fontWeight: baseFontProperties.weight.bold,
    fontFamily: baseFontProperties.family.dm,
    lineHeight: {
      xl: baseFontProperties.lineHeight.size_72,
      lg: baseFontProperties.lineHeight.size_72,
      md: baseFontProperties.lineHeight.size_72,
      sm: baseFontProperties.lineHeight.size_64,
      xs: baseFontProperties.lineHeight.size_64,
    },
    textAlign: "center",
  },
};

export const stateOfFarcasterFirstSectionTextStyles = {
  headTitle: {
    color: baseFontProperties.color.black,
    fontSize: {
      xl: baseFontProperties.sizes.size_60,
      lg: baseFontProperties.sizes.size_60,
      md: baseFontProperties.sizes.size_60,
      sm: baseFontProperties.sizes.size_56,
      xs: baseFontProperties.sizes.size_56,
    },
    fontWeight: baseFontProperties.weight.bold,
    fontFamily: baseFontProperties.family.dm,
    lineHeight: {
      xl: baseFontProperties.lineHeight.size_60,
      lg: baseFontProperties.lineHeight.size_60,
      md: baseFontProperties.lineHeight.size_60,
      sm: baseFontProperties.lineHeight.size_56,
      xs: baseFontProperties.lineHeight.size_56,
    },
    textAlign: "center",
  },
  subTitle: {
    color: baseFontProperties.color.softPurple,
    fontSize: {
      xl: baseFontProperties.sizes.size_60,
      lg: baseFontProperties.sizes.size_60,
      md: baseFontProperties.sizes.size_60,
      sm: baseFontProperties.sizes.size_56,
      xs: baseFontProperties.sizes.size_56,
    },
    fontWeight: baseFontProperties.weight.bold,
    fontFamily: baseFontProperties.family.dm,
    lineHeight: {
      xl: baseFontProperties.lineHeight.size_60,
      lg: baseFontProperties.lineHeight.size_60,
      md: baseFontProperties.lineHeight.size_60,
      sm: baseFontProperties.lineHeight.size_56,
      xs: baseFontProperties.lineHeight.size_56,
    },
    textAlign: "center",
  },
  dateTitle: {
    color: baseFontProperties.color.black,
    fontSize: {
      xl: baseFontProperties.sizes.size_148,
      lg: baseFontProperties.sizes.size_148,
      md: baseFontProperties.sizes.size_148,
      sm: baseFontProperties.sizes.size_140,
      xs: baseFontProperties.sizes.size_140,
    },
    fontWeight: baseFontProperties.weight.bold,
    fontFamily: baseFontProperties.family.dm,
    lineHeight: {
      xl: baseFontProperties.lineHeight.size_128,
      lg: baseFontProperties.lineHeight.size_128,
      md: baseFontProperties.lineHeight.size_128,
      sm: baseFontProperties.lineHeight.size_120,
      xs: baseFontProperties.lineHeight.size_120,
    },
    textAlign: "center",
  },
};

export const columnBarCardTextStyles = {
  title: {
    color: baseFontProperties.color.black,
    fontSize: {
      xl: baseFontProperties.sizes.size_18,
      lg: baseFontProperties.sizes.size_18,
      md: baseFontProperties.sizes.size_18,
      sm: baseFontProperties.sizes.size_12,
      xs: baseFontProperties.sizes.size_12,
    },
    fontWeight: baseFontProperties.weight.bold,
    fontFamily: baseFontProperties.family.dm,
    lineHeight: {
      xl: baseFontProperties.lineHeight.size_28,
      lg: baseFontProperties.lineHeight.size_28,
      md: baseFontProperties.lineHeight.size_28,
      sm: baseFontProperties.lineHeight.size_20,
      xs: baseFontProperties.lineHeight.size_20,
    },
    textAlign: "center",
  },
  value_title: {
    color: baseFontProperties.color.black,
    fontSize: {
      xl: baseFontProperties.sizes.size_16,
      lg: baseFontProperties.sizes.size_16,
      md: baseFontProperties.sizes.size_16,
      sm: baseFontProperties.sizes.size_12,
      xs: baseFontProperties.sizes.size_12,
    },
    fontWeight: baseFontProperties.weight.normal,
    fontFamily: baseFontProperties.family.dm,
    lineHeight: {
      xl: baseFontProperties.lineHeight.size_24,
      lg: baseFontProperties.lineHeight.size_24,
      md: baseFontProperties.lineHeight.size_24,
      sm: baseFontProperties.lineHeight.size_20,
      xs: baseFontProperties.lineHeight.size_20,
    },
    textAlign: "center",
  },
};

export const reportCardTextStyles = {
  list_number: {
    color: baseFontProperties.color.softPurple,
    fontSize: baseFontProperties.sizes.size_20,
    fontWeight: baseFontProperties.weight.normal,
    fontFamily: baseFontProperties.family.dm,
    lineHeight: baseFontProperties.lineHeight.size_28,
  },
  title: {
    color: baseFontProperties.color.black,
    fontSize: baseFontProperties.sizes.size_18,
    fontWeight: baseFontProperties.weight.bold,
    fontFamily: baseFontProperties.family.dm,
    lineHeight: baseFontProperties.lineHeight.size_28,
  },
  description: {
    color: baseFontProperties.color.purpleGray,
    fontSize: baseFontProperties.sizes.size_18,
    fontWeight: baseFontProperties.weight.bold,
    fontFamily: baseFontProperties.family.dm,
    lineHeight: baseFontProperties.lineHeight.size_28,
  },
};

export const rulesTextStyles = {
  headTitle: {
    color: baseFontProperties.color.black,
    fontSize: {
      xl: baseFontProperties.sizes.size_96,
      lg: baseFontProperties.sizes.size_96,
      md: baseFontProperties.sizes.size_48,
      sm: baseFontProperties.sizes.size_48,
      xs: baseFontProperties.sizes.size_48,
    },
    fontWeight: baseFontProperties.weight.bold,
    fontFamily: baseFontProperties.family.dm,
    lineHeight: {
      xl: baseFontProperties.lineHeight.size_96,
      lg: baseFontProperties.lineHeight.size_96,
      md: baseFontProperties.lineHeight.size_42,
      sm: baseFontProperties.lineHeight.size_42,
      xs: baseFontProperties.lineHeight.size_42,
    },
    textAlign: "center",
  },
  title: {
    color: baseFontProperties.color.black,
    fontSize: {
      xl: baseFontProperties.sizes.size_20,
      lg: baseFontProperties.sizes.size_20,
      md: baseFontProperties.sizes.size_20,
      sm: baseFontProperties.sizes.size_16,
      xs: baseFontProperties.sizes.size_16,
    },
    fontWeight: baseFontProperties.weight.bold,
    fontFamily: baseFontProperties.family.dm,
    lineHeight: {
      xl: baseFontProperties.lineHeight.size_28,
      lg: baseFontProperties.lineHeight.size_28,
      md: baseFontProperties.lineHeight.size_28,
      sm: baseFontProperties.lineHeight.size_24,
      xs: baseFontProperties.lineHeight.size_24,
    },
  },
  text: {
    color: baseFontProperties.color.gray,
    fontSize: {
      xl: baseFontProperties.sizes.size_16,
      lg: baseFontProperties.sizes.size_16,
      md: baseFontProperties.sizes.size_16,
      sm: baseFontProperties.sizes.size_12,
      xs: baseFontProperties.sizes.size_12,
    },
    fontWeight: baseFontProperties.weight.normal,
    fontFamily: baseFontProperties.family.dm,
    lineHeight: {
      xl: baseFontProperties.lineHeight.size_24,
      lg: baseFontProperties.lineHeight.size_24,
      md: baseFontProperties.lineHeight.size_24,
      sm: baseFontProperties.lineHeight.size_20,
      xs: baseFontProperties.lineHeight.size_20,
    },
  },
};

export const humanityProtocolCardTextStyles = {
  text: {
    color: baseFontProperties.color.white,
    fontSize: {
      xl: baseFontProperties.sizes.size_20,
      lg: baseFontProperties.sizes.size_20,
      md: baseFontProperties.sizes.size_20,
      sm: baseFontProperties.sizes.size_16,
      xs: baseFontProperties.sizes.size_16,
    },
    fontWeight: baseFontProperties.weight.normal,
    fontFamily: baseFontProperties.family.dm,
    lineHeight: {
      xl: baseFontProperties.lineHeight.size_28,
      lg: baseFontProperties.lineHeight.size_28,
      md: baseFontProperties.lineHeight.size_28,
      sm: baseFontProperties.lineHeight.size_24,
      xs: baseFontProperties.lineHeight.size_24,
    },
  },
  peopletext: {
    color: baseFontProperties.color.mainpurple200,
    fontSize: {
      xl: baseFontProperties.sizes.size_20,
      lg: baseFontProperties.sizes.size_20,
      md: baseFontProperties.sizes.size_20,
      sm: baseFontProperties.sizes.size_16,
      xs: baseFontProperties.sizes.size_16,
    },
    fontWeight: baseFontProperties.weight.normal,
    fontFamily: baseFontProperties.family.dm,
    lineHeight: {
      xl: baseFontProperties.lineHeight.size_28,
      lg: baseFontProperties.lineHeight.size_28,
      md: baseFontProperties.lineHeight.size_28,
      sm: baseFontProperties.lineHeight.size_24,
      xs: baseFontProperties.lineHeight.size_24,
    },
  },
  headTitle: {
    color: baseFontProperties.color.black,
    fontSize: {
      xl: baseFontProperties.sizes.size_36,
      lg: baseFontProperties.sizes.size_36,
      md: baseFontProperties.sizes.size_36,
      sm: baseFontProperties.sizes.size_32,
      xs: baseFontProperties.sizes.size_32,
    },
    fontWeight: baseFontProperties.weight.bold,
    fontFamily: baseFontProperties.family.dm,
    lineHeight: {
      xl: baseFontProperties.lineHeight.size_40,
      lg: baseFontProperties.lineHeight.size_40,
      md: baseFontProperties.lineHeight.size_40,
      sm: baseFontProperties.lineHeight.size_36,
      xs: baseFontProperties.lineHeight.size_36,
    },
    textAlign: "center",
  },
  big_title: {
    color: baseFontProperties.color.black,
    fontSize: {
      xl: baseFontProperties.sizes.size_96,
      lg: baseFontProperties.sizes.size_96,
      md: baseFontProperties.sizes.size_96,
      sm: baseFontProperties.sizes.size_92,
      xs: baseFontProperties.sizes.size_92,
    },
    fontWeight: baseFontProperties.weight.bold,
    fontFamily: baseFontProperties.family.dm,
    lineHeight: {
      xl: baseFontProperties.lineHeight.size_96,
      lg: baseFontProperties.lineHeight.size_96,
      md: baseFontProperties.lineHeight.size_96,
      sm: baseFontProperties.lineHeight.size_92,
      xs: baseFontProperties.lineHeight.size_92,
    },
    textAlign: "center",
  },
  title: {
    color: baseFontProperties.color.black,
    fontSize: {
      xl: baseFontProperties.sizes.size_30,
      lg: baseFontProperties.sizes.size_30,
      md: baseFontProperties.sizes.size_30,
      sm: baseFontProperties.sizes.size_26,
      xs: baseFontProperties.sizes.size_26,
    },
    fontWeight: baseFontProperties.weight.bold,
    fontFamily: baseFontProperties.family.dm,
    lineHeight: {
      xl: baseFontProperties.lineHeight.size_36,
      lg: baseFontProperties.lineHeight.size_36,
      md: baseFontProperties.lineHeight.size_36,
      sm: baseFontProperties.lineHeight.size_32,
      xs: baseFontProperties.lineHeight.size_32,
    },
    textAlign: "center",
  },
};

export const firstSectionTextStyles = {
  text: {
    color: baseFontProperties.color.royalPurple,
    fontSize: {
      xl: baseFontProperties.sizes.size_24,
      lg: baseFontProperties.sizes.size_24,
      md: baseFontProperties.sizes.size_24,
      sm: baseFontProperties.sizes.size_16,
      xs: baseFontProperties.sizes.size_16,
    },
    fontWeight: baseFontProperties.weight.normal,
    fontFamily: baseFontProperties.family.dm,
    lineHeight: {
      xl: baseFontProperties.lineHeight.size_32,
      lg: baseFontProperties.lineHeight.size_32,
      md: baseFontProperties.lineHeight.size_32,
      sm: baseFontProperties.lineHeight.size_24,
      xs: baseFontProperties.lineHeight.size_24,
    },
    textAlign: "center",
  },
  title: {
    color: baseFontProperties.color.black,
    fontSize: {
      xl: baseFontProperties.sizes.size_128,
      lg: baseFontProperties.sizes.size_128,
      md: baseFontProperties.sizes.size_128,
      sm: baseFontProperties.sizes.size_60,
      xs: baseFontProperties.sizes.size_60,
    },
    fontWeight: baseFontProperties.weight.bold,
    fontFamily: baseFontProperties.family.dm,
    lineHeight: {
      xl: baseFontProperties.lineHeight.size_128,
      lg: baseFontProperties.lineHeight.size_128,
      md: baseFontProperties.lineHeight.size_128,
      sm: baseFontProperties.lineHeight.size_60,
      xs: baseFontProperties.lineHeight.size_60,
    },
    textAlign: "center",
  },
  subtext: {
    color: baseFontProperties.color.royalPurple,
    fontSize: {
      xl: baseFontProperties.sizes.size_18,
      lg: baseFontProperties.sizes.size_18,
      md: baseFontProperties.sizes.size_18,
      sm: baseFontProperties.sizes.size_14,
      xs: baseFontProperties.sizes.size_14,
    },
    fontWeight: baseFontProperties.weight.normal,
    fontFamily: baseFontProperties.family.dm,
    lineHeight: {
      xl: baseFontProperties.lineHeight.size_28,
      lg: baseFontProperties.lineHeight.size_28,
      md: baseFontProperties.lineHeight.size_28,
      sm: baseFontProperties.lineHeight.size_24,
      xs: baseFontProperties.lineHeight.size_24,
    },
    textAlign: "center",
  },
};

export const thirdSectionTextStyles = {
  nodata_text: {
    color: baseFontProperties.color.royalPurple,
    fontSize: {
      xl: baseFontProperties.sizes.size_16,
      lg: baseFontProperties.sizes.size_16,
      md: baseFontProperties.sizes.size_16,
      sm: baseFontProperties.sizes.size_16,
      xs: baseFontProperties.sizes.size_16,
    },
    fontWeight: baseFontProperties.weight.normal,
    fontFamily: baseFontProperties.family.dm,
    lineHeight: {
      xl: baseFontProperties.lineHeight.size_24,
      lg: baseFontProperties.lineHeight.size_24,
      md: baseFontProperties.lineHeight.size_24,
      sm: baseFontProperties.lineHeight.size_24,
      xs: baseFontProperties.lineHeight.size_24,
    },
    textAlign: "center",
  },
};

export const fourthSectionTextStyles = {
  title: {
    color: baseFontProperties.color.black,
    fontSize: {
      xl: baseFontProperties.sizes.size_72,
      lg: baseFontProperties.sizes.size_72,
      md: baseFontProperties.sizes.size_72,
      sm: baseFontProperties.sizes.size_36,
      xs: baseFontProperties.sizes.size_36,
    },
    fontWeight: baseFontProperties.weight.bold,
    fontFamily: baseFontProperties.family.dm,
    lineHeight: {
      xl: baseFontProperties.lineHeight.size_72,
      lg: baseFontProperties.lineHeight.size_72,
      md: baseFontProperties.lineHeight.size_72,
      sm: baseFontProperties.lineHeight.size_40,
      xs: baseFontProperties.lineHeight.size_40,
    },
    textAlign: "center",
  },
  description: {
    color: baseFontProperties.color.royalPurple,
    fontSize: {
      xl: baseFontProperties.sizes.size_24,
      lg: baseFontProperties.sizes.size_24,
      md: baseFontProperties.sizes.size_24,
      sm: baseFontProperties.sizes.size_16,
      xs: baseFontProperties.sizes.size_16,
    },
    fontWeight: baseFontProperties.weight.normal,
    fontFamily: baseFontProperties.family.dm,
    lineHeight: {
      xl: baseFontProperties.lineHeight.size_32,
      lg: baseFontProperties.lineHeight.size_32,
      md: baseFontProperties.lineHeight.size_32,
      sm: baseFontProperties.lineHeight.size_24,
      xs: baseFontProperties.lineHeight.size_24,
    },
    textAlign: "center",
  },
};

export const accordionTextStyles = {
  question: {
    color: baseFontProperties.color.black,
    fontSize: {
      xl: baseFontProperties.sizes.size_20,
      lg: baseFontProperties.sizes.size_20,
      md: baseFontProperties.sizes.size_20,
      sm: baseFontProperties.sizes.size_16,
      xs: baseFontProperties.sizes.size_16,
    },
    fontWeight: baseFontProperties.weight.bold,
    fontFamily: baseFontProperties.family.dm,
    lineHeight: {
      xl: baseFontProperties.lineHeight.size_28,
      lg: baseFontProperties.lineHeight.size_28,
      md: baseFontProperties.lineHeight.size_28,
      sm: baseFontProperties.lineHeight.size_24,
      xs: baseFontProperties.lineHeight.size_24,
    },
  },
  answer: {
    color: baseFontProperties.color.gray,
    fontSize: {
      xl: baseFontProperties.sizes.size_18,
      lg: baseFontProperties.sizes.size_18,
      md: baseFontProperties.sizes.size_18,
      sm: baseFontProperties.sizes.size_16,
      xs: baseFontProperties.sizes.size_16,
    },
    fontWeight: baseFontProperties.weight.normal,
    fontFamily: baseFontProperties.family.dm,
    lineHeight: {
      xl: baseFontProperties.lineHeight.size_28,
      lg: baseFontProperties.lineHeight.size_28,
      md: baseFontProperties.lineHeight.size_28,
      sm: baseFontProperties.lineHeight.size_24,
      xs: baseFontProperties.lineHeight.size_24,
    },
  },
};

export const productDetailsTextStyles = {
  sdlTitle: {
    color: baseFontProperties.color.black,
    fontSize: {
      xl: baseFontProperties.sizes.size_24,
      lg: baseFontProperties.sizes.size_24,
      md: baseFontProperties.sizes.size_24,
      sm: baseFontProperties.sizes.size_24,
      xs: baseFontProperties.sizes.size_16,
    },
    fontFamily: baseFontProperties.family.dm,
    fontWeight: {
      xl: baseFontProperties.weight.medium,
      lg: baseFontProperties.weight.medium,
      md: baseFontProperties.weight.medium,
      sm: baseFontProperties.weight.medium,
      xs: baseFontProperties.weight.normal,
    },
    lineHeight: {
      xl: baseFontProperties.lineHeight.size_32,
      lg: baseFontProperties.lineHeight.size_32,
      md: baseFontProperties.lineHeight.size_32,
      sm: baseFontProperties.lineHeight.size_32,
      xs: baseFontProperties.lineHeight.size_24,
    },
  },
  productTitle: {
    fontFamily: baseFontProperties.family.dm,
    color: baseFontProperties.color.black,
    fontSize: {
      xl: baseFontProperties.sizes.size_128,
      lg: baseFontProperties.sizes.size_128,
      md: baseFontProperties.sizes.size_128,
      sm: baseFontProperties.sizes.size_128,
      xs: baseFontProperties.sizes.size_48,
    },
    fontWeight: baseFontProperties.weight.bold,
    lineHeight: {
      xl: baseFontProperties.lineHeight.size_128,
      lg: baseFontProperties.lineHeight.size_128,
      md: baseFontProperties.lineHeight.size_128,
      sm: baseFontProperties.lineHeight.size_128,
      xs: baseFontProperties.lineHeight.size_48,
    },
    textAlign: "center",
  },
  productDescription: {
    fontFamily: baseFontProperties.family.dm,

    color: baseFontProperties.color.royalPurple,
    fontSize: {
      xl: baseFontProperties.sizes.size_24,
      lg: baseFontProperties.sizes.size_24,
      md: baseFontProperties.sizes.size_24,
      sm: baseFontProperties.sizes.size_24,
      xs: baseFontProperties.sizes.size_16,
    },
    fontWeight: baseFontProperties.weight.normal,
    lineHeight: {
      xl: baseFontProperties.lineHeight.size_32,
      lg: baseFontProperties.lineHeight.size_32,
      md: baseFontProperties.lineHeight.size_32,
      sm: baseFontProperties.lineHeight.size_32,
      xs: baseFontProperties.lineHeight.size_24,
    },
    textAlign: "center",
  },
  followUs: {
    fontSize: baseFontProperties.sizes.size_18,
    fontFamily: baseFontProperties.family.dm,
    lineHeight: baseFontProperties.lineHeight.size_28,
    color: baseFontProperties.color.royalPurple,
  },
};

export const subscribeTextStyles = {
  title: {
    color: baseFontProperties.color.white,
    fontSize: baseFontProperties.sizes.size_36,
    fontWeight: baseFontProperties.weight.bold,
    fontFamily: baseFontProperties.family.dm,
    lineHeight: baseFontProperties.lineHeight.size_40,
    textAlign: "center",
  },
  text: {
    color: baseFontProperties.color.mainpurple100,
    fontSize: baseFontProperties.sizes.size_18,
    fontWeight: baseFontProperties.weight.normal,
    fontFamily: baseFontProperties.family.dm,
    lineHeight: baseFontProperties.lineHeight.size_28,
    textAlign: "center",
  },
};

export const shareToEarnTextStyles = {
  pageTitle: {
    color: baseFontProperties.color.black,
    fontSize: {
      xl: baseFontProperties.sizes.size_72,
      lg: baseFontProperties.sizes.size_72,
      md: baseFontProperties.sizes.size_72,
      sm: baseFontProperties.sizes.size_36,
      xs: baseFontProperties.sizes.size_36,
    },
    fontWeight: baseFontProperties.weight.bold,
    fontFamily: baseFontProperties.family.dm,
    lineHeight: {
      xl: baseFontProperties.lineHeight.size_72,
      lg: baseFontProperties.lineHeight.size_72,
      md: baseFontProperties.lineHeight.size_72,
      sm: baseFontProperties.lineHeight.size_40,
      xs: baseFontProperties.lineHeight.size_40,
    },
    textAlign: "center",
  },
  stepNumber: {
    color: baseFontProperties.color.purpleGray,
    fontSize: {
      xl: baseFontProperties.sizes.size_18,
      lg: baseFontProperties.sizes.size_18,
      md: baseFontProperties.sizes.size_18,
      sm: baseFontProperties.sizes.size_16,
      xs: baseFontProperties.sizes.size_16,
    },
    fontWeight: baseFontProperties.weight.normal,
    fontFamily: baseFontProperties.family.dm,
    lineHeight: {
      xl: baseFontProperties.lineHeight.size_28,
      lg: baseFontProperties.lineHeight.size_28,
      md: baseFontProperties.lineHeight.size_28,
      sm: baseFontProperties.lineHeight.size_24,
      xs: baseFontProperties.lineHeight.size_24,
    },
    textAlign: "center",
  },
  stepTitle: {
    color: baseFontProperties.color.black,
    fontSize: {
      xl: baseFontProperties.sizes.size_36,
      lg: baseFontProperties.sizes.size_36,
      md: baseFontProperties.sizes.size_36,
      sm: baseFontProperties.sizes.size_24,
      xs: baseFontProperties.sizes.size_24,
    },
    fontWeight: baseFontProperties.weight.bold,
    fontFamily: baseFontProperties.family.dm,
    lineHeight: {
      xl: baseFontProperties.lineHeight.size_40,
      lg: baseFontProperties.lineHeight.size_40,
      md: baseFontProperties.lineHeight.size_40,
      sm: baseFontProperties.lineHeight.size_32,
      xs: baseFontProperties.lineHeight.size_32,
    },
    textAlign: "center",
    letter: "-5%",
  },
  stepDescription: {
    color: baseFontProperties.color.gray,
    fontSize: {
      xl: baseFontProperties.sizes.size_20,
      lg: baseFontProperties.sizes.size_20,
      md: baseFontProperties.sizes.size_20,
      sm: baseFontProperties.sizes.size_16,
      xs: baseFontProperties.sizes.size_16,
    },
    fontWeight: baseFontProperties.weight.normal,
    fontFamily: baseFontProperties.family.dm,
    lineHeight: {
      xl: baseFontProperties.lineHeight.size_28,
      lg: baseFontProperties.lineHeight.size_28,
      md: baseFontProperties.lineHeight.size_28,
      sm: baseFontProperties.lineHeight.size_24,
      xs: baseFontProperties.lineHeight.size_24,
    },
    textAlign: "center",
  },
};
export const productsTextStyles = {
  pageTitle: {
    color: baseFontProperties.color.black,
    fontSize: {
      xl: baseFontProperties.sizes.size_96,
      lg: baseFontProperties.sizes.size_96,
      md: baseFontProperties.sizes.size_96,
      sm: baseFontProperties.sizes.size_48,
      xs: baseFontProperties.sizes.size_48,
    },
    fontWeight: baseFontProperties.weight.medium,
    fontFamily: baseFontProperties.family.dm,
    lineHeight: {
      xl: baseFontProperties.lineHeight.size_96,
      lg: baseFontProperties.lineHeight.size_96,
      md: baseFontProperties.lineHeight.size_96,
      sm: baseFontProperties.lineHeight.size_48,
      xs: baseFontProperties.lineHeight.size_48,
    },
    textAlign: "center",
  },
  pageDescription: {
    color: baseFontProperties.color.royalPurple,
    fontSize: {
      xl: baseFontProperties.sizes.size_20,
      lg: baseFontProperties.sizes.size_20,
      md: baseFontProperties.sizes.size_20,
      sm: baseFontProperties.sizes.size_16,
      xs: baseFontProperties.sizes.size_16,
    },
    fontWeight: baseFontProperties.weight.normal,
    fontFamily: baseFontProperties.family.dm,
    lineHeight: {
      xl: baseFontProperties.lineHeight.size_28,
      lg: baseFontProperties.lineHeight.size_28,
      md: baseFontProperties.lineHeight.size_28,
      sm: baseFontProperties.lineHeight.size_24,
      xs: baseFontProperties.lineHeight.size_24,
    },
    textAlign: "center",
  },
  cardTitle: {
    color: baseFontProperties.color.black,
    fontSize: {
      xl: baseFontProperties.sizes.size_24,
      lg: baseFontProperties.sizes.size_24,
      md: baseFontProperties.sizes.size_24,
      sm: baseFontProperties.sizes.size_20,
      xs: baseFontProperties.sizes.size_20,
    },
    fontWeight: baseFontProperties.weight.bold,
    fontFamily: baseFontProperties.family.dm,
    lineHeight: {
      xl: baseFontProperties.lineHeight.size_32,
      lg: baseFontProperties.lineHeight.size_32,
      md: baseFontProperties.lineHeight.size_32,
      sm: baseFontProperties.lineHeight.size_28,
      xs: baseFontProperties.lineHeight.size_28,
    },
    textAlign: "left",
  },
  cardDescription: {
    color: baseFontProperties.color.gray,
    fontSize: baseFontProperties.sizes.size_16,
    fontWeight: baseFontProperties.weight.normal,
    fontFamily: baseFontProperties.family.dm,
    lineHeight: baseFontProperties.lineHeight.size_24,
    textAlign: "left",
  },
};

export const featuresTextStyles = {
  headline_feature: {
    color: baseFontProperties.color.black,
    fontFamily: baseFontProperties.family.dm,
    fontSize: baseFontProperties.sizes.size_20,
    fontWeight: baseFontProperties.weight.xbold,
    lineHeight: baseFontProperties.lineHeight.size_28,
    textAlign: "center",
  },
  body_feature: {
    color: baseFontProperties.color.gray,
    fontFamily: baseFontProperties.family.dm,
    fontSize: baseFontProperties.sizes.size_16,
    fontWeight: baseFontProperties.weight.normal,
    lineHeight: baseFontProperties.lineHeight.size_24,
    textAlign: "center",
  },
  body_date_feature: {
    color: baseFontProperties.color.purpleGray,
    fontFamily: baseFontProperties.family.dm,
    fontSize: baseFontProperties.sizes.size_16,
    fontWeight: baseFontProperties.weight.normal,
    lineHeight: baseFontProperties.lineHeight.size_24,
    textAlign: "center",
  },
};
export const eventsTextStyles = {
  headline_event: {
    color: baseFontProperties.color.black,
    fontFamily: baseFontProperties.family.dm,
    fontSize: baseFontProperties.sizes.size_20,
    fontWeight: baseFontProperties.weight.xbold,
    lineHeight: baseFontProperties.lineHeight.size_28,
    textAlign: "left",
  },
  body_rsvp: {
    color: baseFontProperties.color.softPurple,
    fontFamily: baseFontProperties.family.dm,
    fontSize: baseFontProperties.sizes.size_16,
    lineHeight: baseFontProperties.lineHeight.size_24,
    transition: "all 0.3s ease-in-out",
  },
  body_date_event: {
    color: baseFontProperties.color.purpleGray,
    fontFamily: baseFontProperties.family.dm,
    fontSize: baseFontProperties.sizes.size_16,
    fontWeight: baseFontProperties.weight.normal,
    lineHeight: baseFontProperties.lineHeight.size_24,
    textAlign: "left",
  },
};
export const spacesShowsTextStyles = {
  body_spaces: {
    color: baseFontProperties.color.gray,
    fontFamily: baseFontProperties.family.dm,
    fontSize: baseFontProperties.sizes.size_16,
    fontWeight: baseFontProperties.weight.normal,
    lineHeight: baseFontProperties.lineHeight.size_24,
    textAlign: "left",
  },
  body_date_spaces: {
    color: baseFontProperties.color.purpleGray,
    fontFamily: baseFontProperties.family.dm,
    fontSize: baseFontProperties.sizes.size_16,
    fontWeight: baseFontProperties.weight.normal,
    lineHeight: baseFontProperties.lineHeight.size_24,
    textAlign: "left",
  },
};

export const spacesEpisodesTextStyles = {
  date_episodes: {
    color: baseFontProperties.color.purpleGray,
    fontFamily: baseFontProperties.family.dm,
    fontSize: baseFontProperties.sizes.size_16,
    fontWeight: baseFontProperties.weight.normal,
    lineHeight: baseFontProperties.lineHeight.size_24,
    textAlign: "left",
  },
  title_episodes: {
    color: baseFontProperties.color.black,
    fontFamily: baseFontProperties.family.dm,
    fontSize: baseFontProperties.sizes.size_16,
    fontWeight: baseFontProperties.weight.xbold,
    lineHeight: baseFontProperties.lineHeight.size_24,
  },
  listen_episodes: {
    color: baseFontProperties.color.softPurple,
    transition: "all 0.3s ease-in-out",
    fontFamily: baseFontProperties.family.dm,
    fontSize: {
      xl: baseFontProperties.sizes.size_16,
      lg: baseFontProperties.sizes.size_16,
      md: baseFontProperties.sizes.size_16,
      sm: baseFontProperties.sizes.size_14,
      xs: baseFontProperties.sizes.size_14,
    },
    fontWeight: baseFontProperties.weight.xbold,
    lineHeight: {
      xl: baseFontProperties.lineHeight.size_24,
      lg: baseFontProperties.lineHeight.size_24,
      md: baseFontProperties.lineHeight.size_24,
      sm: baseFontProperties.lineHeight.size_20,
      xs: baseFontProperties.lineHeight.size_20,
    },
  },
};

export const featuresDetailsTextStyles = {
  header_featureDetails: {
    color: baseFontProperties.color.white,
    fontFamily: baseFontProperties.family.shrikhand,
    fontSize: baseFontProperties.sizes.size_48,
    // fontWeight: baseFontProperties.weight.normal,
    lineHeight: baseFontProperties.lineHeight.size_56,
    textAlign: "center",
    letterSpacing: "-0.02em",
    WebkitTextFillColor: baseFontProperties.color.white,
    textShadow: `
      2px 0px 0 rgba(41, 38, 72, 1),
      -2px 0px 0 rgba(41, 38, 72, 1),
      0px -2.5px 0px rgba(41, 38, 72, 1),
      0px 2px 0px rgba(41, 38, 72, 1),
      5px 5px 0px rgba(110, 98, 166, 0.44)
    `,
  },
  date_featureDetails: {
    color: baseFontProperties.color.royalPurple,
    fontFamily: baseFontProperties.family.dm,
    fontSize: baseFontProperties.sizes.size_20,
    fontWeight: baseFontProperties.weight.medium,
    lineHeight: baseFontProperties.lineHeight.size_24,
    opacity: "0.56",
  },
  text_featureDetails: {
    color: baseFontProperties.color.black,
    fontFamily: baseFontProperties.family.dm,
    fontSize: baseFontProperties.sizes.size_20,
    fontWeight: baseFontProperties.weight.normal,
    lineHeight: baseFontProperties.lineHeight.size_32,
    whiteSpace: "pre-line",
  },
};

export const blogTextStyles = {
  header_blog: {
    color: baseFontProperties.color.black,
    fontFamily: baseFontProperties.family.dm,
    fontSize: {
      xl: baseFontProperties.sizes.size_24,
      lg: baseFontProperties.sizes.size_24,
      md: baseFontProperties.sizes.size_24,
      sm: baseFontProperties.sizes.size_20,
      xs: baseFontProperties.sizes.size_20,
    },
    fontWeight: baseFontProperties.weight.xbold,
    lineHeight: baseFontProperties.lineHeight.size_32,
  },
  description_blog: {
    color: baseFontProperties.color.gray,
    fontFamily: baseFontProperties.family.dm,
    fontSize: baseFontProperties.sizes.size_16,
    fontWeight: baseFontProperties.weight.normal,
    lineHeight: baseFontProperties.lineHeight.size_24,
  },
  date_blog: {
    color: baseFontProperties.color.purpleGray,
    fontFamily: baseFontProperties.family.dm,
    fontSize: baseFontProperties.sizes.size_16,
    fontWeight: baseFontProperties.weight.normal,
    lineHeight: baseFontProperties.lineHeight.size_24,
  },
};

export const blogDetailsTextStyles = {
  header_title: {
    color: baseFontProperties.color.black,
    fontFamily: baseFontProperties.family.dm,
    fontSize: baseFontProperties.sizes.size_72,
    lineHeight: baseFontProperties.lineHeight.size_72,
    fontWeight: baseFontProperties.weight.bold,
  },
  header_featureDetails: {
    color: baseFontProperties.color.white,
    fontFamily: baseFontProperties.family.shrikhand,
    fontSize: baseFontProperties.sizes.size_48,
    // fontWeight: baseFontProperties.weight.normal,
    lineHeight: baseFontProperties.lineHeight.size_56,
    letterSpacing: "-0.02em",
    WebkitTextFillColor: baseFontProperties.color.white,
    textShadow: `
      2px 0px 0 rgba(41, 38, 72, 1),
      -2px 0px 0 rgba(41, 38, 72, 1),
      0px -2.5px 0px rgba(41, 38, 72, 1),
      0px 2px 0px rgba(41, 38, 72, 1),
      5px 5px 0px rgba(110, 98, 166, 0.44)
    `,
  },
  date_featureDetails: {
    color: baseFontProperties.color.royalPurple,
    fontFamily: baseFontProperties.family.dm,
    fontSize: baseFontProperties.sizes.size_16,
    fontWeight: baseFontProperties.weight.normal,
    lineHeight: baseFontProperties.lineHeight.size_24,
    opacity: "0.56",
  },
};

export const footerTextStyles = {
  follow_us: {
    color: baseFontProperties.color.white,
    fontFamily: baseFontProperties.family.dm,
    fontSize: baseFontProperties.sizes.size_16,
    lineHeight: baseFontProperties.lineHeight.size_24,
    fontWeight: baseFontProperties.weight.normal,
    opacity: 0.44,
    mr: 2,
  },
  terms_privacy: {
    color: baseFontProperties.color.white,
    fontFamily: baseFontProperties.family.dm,
    fontSize: baseFontProperties.sizes.size_18,
    lineHeight: baseFontProperties.lineHeight.size_28,
    fontWeight: baseFontProperties.weight.normal,
  },
};

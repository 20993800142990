export enum EPageTags {
  SPACEPAGE = "spacePage",
  FEATUREPAGE = "featuresPage",
  BLOGPAGE = "blogPage",
  EVENTPAGE = "eventPage",
  FARIRLPAGE = "farIrlPage",
}

export enum EEntriesTags {
  SPACEEPISODE = "spaceEpisode",
  SPACESHOW = "spaceShow",
  FEATURE = "feature",
  BLOG = "blog",
  EVENT = "event",
  FARIRL = "farIrl",
  FAQ = "faq",
}

export enum EEntriesID {
  SPACEEPISODE = "spaceEpisode",
  SPACESHOW = "spaceShow",
  FEATURE = "componentQuote",
  BLOG = "componentDuplex",
  EVENT = "event",
  FAQ = "faq",
}

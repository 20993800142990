import React, {
  createContext,
  FC,
  PropsWithChildren,
  useCallback,
  useContext,
  useEffect,
  useRef,
} from "react";
import ReactGA from "react-ga4";
import { useStoreContext } from "./StoreContext";
import { FarcasterAdId } from "@sdl-marketing/objects/primitives/FarcasterAdId";

interface IProviderValues {
  sendCastClickEvent: (id: FarcasterAdId) => void;
}

const AnalyticsContext = createContext<IProviderValues>({} as IProviderValues);

export const AnalyticsProvider: FC<PropsWithChildren> = ({ children }) => {
  const initalizedRef = useRef(false);
  const { apiGateway } = useStoreContext();

  useEffect(() => {
    initializeGA();
    sendPageView();
  }, []);

  const initializeGA = useCallback(() => {
    if (apiGateway.config.gaId && !initalizedRef.current) {
      ReactGA.initialize(apiGateway.config.gaId);
      initalizedRef.current = true;
    }
  }, [apiGateway.config.gaId]);

  const sendCastClickEvent = useCallback((id: FarcasterAdId) => {
    if (initalizedRef.current) {
      ReactGA.send({
        hitType: "event",
        eventCategory: "Cast",
        eventAction: "Click",
        eventLabel: id,
      });
    }
  }, []);

  const sendPageView = useCallback(() => {
    if (initalizedRef.current) {
      ReactGA.send({
        hitType: "pageview",
        page: window.location.pathname,
      });
    }
  }, []);

  return (
    <AnalyticsContext.Provider value={{ sendCastClickEvent }}>
      {children}
    </AnalyticsContext.Provider>
  );
};

export const useAnalyticsContext = () => useContext(AnalyticsContext);

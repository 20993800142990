export const calcSizeWidth = (size: number | string) =>
  `calc(100vw*${size}/1920)`;
export const calcSizeHeight = (size: number | string) =>
  `calc(100vh*${size}/1080)`;

export const baseFontProperties = {
  color: {
    white: "#FFFFFF",
    black: "#292648",
    gray: "#585668",
    purpleGray: "#8A8898",
    softPurple: "#6E62A6",
    royalPurple: " #4E4676",
    mainpurple100: "#D2CEE3",
    mainpurple200: "#BCB7D6",
  },
  sizes: {
    size_10: "10px",
    size_12: "12px",
    size_14: "14px",
    size_16: "16px",
    size_18: "18px",
    size_20: "20px",
    size_22: "22px",
    size_24: "24px",
    size_26: "26px",
    size_30: "30px",
    size_32: "32px",
    size_34: "34px",
    size_36: "36px",
    size_38: "38px",
    size_40: "40px",
    size_48: "48px",
    size_56: "56px",
    size_60: "60px",
    size_64: "64px",
    size_72: "72px",
    size_82: "82px",
    size_92: "92px",
    size_96: "96px",
    size_100: "100px",
    size_120: "120px",
    size_128: "128px",
    size_140: "140px",
    size_148: "148px",
  },
  weight: {
    normal: 400,
    medium: 500,
    bold: 600,
    xbold: 700,
    xxbold: 800,
    xxxbold: 900,
  },
  family: {
    inter: "Inter, sans-serif",
    montserrat: "Montserrat, sans-serif",
    karla: "Karla, sans-serif",
    dm: "DM Sans, sans-serif",
    shrikhand: "Shrikhand",
  },
  lineHeight: {
    size_12: "12px",
    size_14: "14px",
    size_16: "16px",
    size_20: "20px",
    size_22: "22px",
    size_24: "24px",
    size_26: "26px",
    size_28: "28px",
    size_30: "30px",
    size_32: "32px",
    size_36: "36px",
    size_38: "38px",
    size_40: "40px",
    size_42: "42px",
    size_44: "44px",
    size_48: "48px",
    size_56: "56px",
    size_60: "60px",
    size_64: "64px",
    size_72: "72px",
    size_78: "78px",
    size_80: "80px",
    size_92: "92px",
    size_96: "96px",
    size_100: "100px",
    size_112: "112px",
    size_120: "120px",
    size_128: "128px",
  },
};

export const createTextStyle = (
  color: keyof (typeof baseFontProperties)["color"],
  size: keyof (typeof baseFontProperties)["sizes"],
  weight: keyof (typeof baseFontProperties)["weight"],
  family: keyof (typeof baseFontProperties)["family"]
) => ({
  color: baseFontProperties.color[color],
  fontSize: baseFontProperties.sizes[size],
  fontWeight: baseFontProperties.weight[weight],
  fontFamily: baseFontProperties.family[family],
});

import Box from "@mui/material/Box";
import { SvgString } from "@sdl-marketing/objects/primitives/SvgString";
import React, { FC, useEffect, useRef } from "react";

interface ISvgRendererProps {
	svg: SvgString;
}

const convertImageToStaticImage = (
	imgUrl: string,
	callback: (dataUrl: string) => void,
) => {
	const img = new Image();
	img.crossOrigin = "Anonymous";
	img.src = imgUrl;

	img.onload = (e) => {
		const canvas = document.createElement("canvas");
		const ctx = canvas.getContext("2d");

		if (!ctx) {
			console.error("Failed to get canvas context");
			callback("");
			return;
		}

		canvas.width = img.width;
		canvas.height = img.height;

		ctx.drawImage(img, 0, 0, img.width, img.height);

		const dataUrl = canvas.toDataURL("image/png");

		callback(dataUrl);
	};

	img.onerror = () => {
		callback("");
	};
};

export const SvgRenderer: FC<ISvgRendererProps> = ({ svg }) => {
	const svgRef = useRef<HTMLDivElement | null>(null);
	useEffect(() => {
		const svgElement = svgRef.current;

		if (svgElement) {
			const imageElements = svgElement.querySelectorAll("image");

			const hasPriorityImages = Array.from(imageElements).some(
				(imageElement) => {
					const priority = imageElement.getAttribute(
						"data-animation-priority",
					);
					return priority !== null && priority !== "";
				},
			);

			if (hasPriorityImages) {
				// Sort by priority and pick the highest priority image
				const imageElementsNeedsToConvert = Array.from(
					imageElements,
				).sort((a, b) => {
					const aP = a.getAttribute("data-animation-priority") || "0";
					const bP = b.getAttribute("data-animation-priority") || "0";
					return parseInt(bP) - parseInt(aP);
				});

				// Convert remaining images to static images
				imageElementsNeedsToConvert.slice(1).forEach((imageElement) => {
					const imgUrl = imageElement.getAttribute("href") || "";
					imageElement.setAttribute("visibility", "hidden");
					convertImageToStaticImage(imgUrl, (dataUrl) => {
						if (dataUrl) {
							imageElement.setAttribute("href", dataUrl);
						}
						imageElement.setAttribute("visibility", "visible");
					});
				});
			}
		}
	}, [svg]);
	return (
		<Box
			ref={svgRef}
			sx={{
				position: "relative",
				"& svg": {
					width: "100%",
					height: "auto",
					display: "block",
				},
			}}
			dangerouslySetInnerHTML={{
				__html: svg,
			}}
		/>
	);
};

export enum ERoutes {
  LANDING_PAGE = "/",
  PRODUCT_PAGE = "/products",
  STATE_OF_FARCASTER_PAGE = "/state-farcaster",
  PROTOCOL_PAGE = "/protocol",
  SPACES_PAGE = "/spaces",
  FEATURES_PAGE = "/features",
  BLOG_PAGE = "/blog",
  EVENTS_PAGE = "/events",
  TERMS_AND_CONDITIONS_PAGE = "/terms-conditions",
  PRIVACY_POLICY_PAGE = "/privacy-policy",
  NOMATCH = "/*",
}

export enum EProductRoutes {
  SHARE_TO_EARN_NETWORK = "/share-to-earn-network",
  SNICKERDOODLE_WALLET = "/snickerdoodle-wallet",
  SNICKERDOODLE_VAULT = "/snickerdoodle-vault",
  SNICKERDOODLE_ADS = "/snickerdoodle-ads",
  SNICKERDOODLE_TASKS = "/snickerdoodle-tasks",
  SNICKERDOODLE_SURVEYS = "/snickerdoodle-surveys",
  SNICKERDOODLE_AUDIENCE = "/snickerdoodle-audience",
  SNICKERDOODLE_CREATORS = "/snickerdoodle-creators",
  SNICKERDOODLE_NETWORKS = "/snickerdoodle-networks",
}

export enum ECommonRoutes {
  SNICKERDOODLE_MAIN = "https://app.snickerdoodle.com/",
  SNICKERDOODLE_ADVERTISING = "https://docs.google.com/forms/d/e/1FAIpQLSd0UyhcIrgyCiJtwSqhEs5zqDzA0-Ysm8gfLxiBjVT3zJjaBQ/viewform",
}

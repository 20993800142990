import { red } from "@mui/material/colors";
import { createTheme } from "@mui/material/styles";


// Extend the Palette and PaletteOptions interfaces to include custom colors
declare module "@mui/material/styles" {
  interface Palette {
    customColors: CustomPaletteColorOptions;
  }
  interface PaletteOptions {
    customColors?: CustomPaletteColorOptions;
  }
}

interface CustomPaletteColorOptions {
  DEFAULT_LIGHT_BG: string;
  DEFAULT_DARK_BG: string;
  WHITE: string;

  MAINPURPLE50: string;
  MAINPURPLE100: string;
  MAINPURPLE200: string;
  MAINPURPLE300: string;
  MAINPURPLE400: string;
  MAINPURPLE500: string;
  MAINPURPLE600: string;
  MAINPURPLE700: string;
  MAINPURPLE800: string;
  MAINPURPLE900: string;

  DARKPURPLE50: string;
  DARKPURPLE100: string;
  DARKPURPLE200: string;
  DARKPURPLE300: string;
  DARKPURPLE400: string;
  DARKPURPLE500: string;
  DARKPURPLE600: string;
  DARKPURPLE700: string;
  DARKPURPLE800: string;
  DARKPURPLE900: string;

  MINT50: string;
  MINT100: string;
  MINT200: string;
  MINT300: string;
  MINT400: string;
  MINT500: string;
  MINT600: string;
  MINT700: string;
  MINT800: string;
  MINT900: string;

  YELLOW50: string;
  YELLOW100: string;
  YELLOW200: string;
  YELLOW300: string;
  YELLOW400: string;
  YELLOW500: string;
  YELLOW600: string;
  YELLOW700: string;
  YELLOW800: string;
  YELLOW900: string;

  RED50: string;
  RED100: string;
  RED200: string;
  RED300: string;
  RED400: string;
  RED500: string;
  RED600: string;
  RED700: string;
  RED800: string;
  RED900: string;

  SUNRISE50: string;
  SUNRISE100: string;
  SUNRISE200: string;
  SUNRISE300: string;
  SUNRISE400: string;
  SUNRISE500: string;
  SUNRISE600: string;
  SUNRISE700: string;
  SUNRISE800: string;
  SUNRISE900: string;

  GREEN50: string;
  GREEN100: string;
  GREEN200: string;
  GREEN300: string;
  GREEN400: string;
  GREEN500: string;
  GREEN600: string;
  GREEN700: string;
  GREEN800: string;
  GREEN900: string;

  BLUE50: string;
  BLUE100: string;
  BLUE200: string;
  BLUE300: string;
  BLUE400: string;
  BLUE500: string;
  BLUE600: string;
  BLUE700: string;
  BLUE800: string;
  BLUE900: string;

  GREY900: string;
  GREY800: string;
  GREY700: string;
  GREY600: string;
  GREY500: string;
  GREY400: string;
  GREY300: string;
  GREY100: string;
  GREY50: string;
}

// Define your custom color palette
const customPalette: CustomPaletteColorOptions = {
  // BgColors
  DEFAULT_LIGHT_BG: "rgba(250, 250, 250, 0.98)",
  DEFAULT_DARK_BG: "#000",

  WHITE: "#FFFFFF",
  // main purple
  MAINPURPLE50: "#f1eff6",
  MAINPURPLE100: "#d2cee3",
  MAINPURPLE200: "#bcb7d6",
  MAINPURPLE300: "#9e96c3",
  MAINPURPLE400: "#8079b4",
  MAINPURPLE500: "#6e62a6", // MAIN
  MAINPURPLE600: "#645997",
  MAINPURPLE700: "#4e4676",
  MAINPURPLE800: "#3d365b",
  MAINPURPLE900: "#2e2946",

  // dark purple
  DARKPURPLE50: "#eae9ed",
  DARKPURPLE100: "#dfdee4",
  DARKPURPLE200: "#bdbcc6",
  DARKPURPLE300: "#8A8898",
  DARKPURPLE400: "#585668",
  DARKPURPLE500: "#292648", // MAIM
  DARKPURPLE600: "#232039",
  DARKPURPLE700: "#19172b",
  DARKPURPLE800: "#121120",
  DARKPURPLE900: "#0e0d19",

  // mint
  MINT50: "#eaf5f3",
  MINT100: "#bce1db",
  MINT200: "#9cd3c9",
  MINT300: "#6fbfb1",
  MINT400: "#53b2a1",
  MINT500: "#289f8a",
  MINT600: "#24917e",
  MINT700: "#1c7162",
  MINT800: "#16574c",
  MINT900: "#11433a",

  // yellow
  YELLOW50: "#fffbf4",
  YELLOW100: "#fff2dc",
  YELLOW200: "#ffeccb",
  YELLOW300: "#ffe4b4",
  YELLOW400: "#ffdea5",
  YELLOW500: "#ffd68f", // MAIN
  YELLOW600: "#e8c382",
  YELLOW700: "#b59866",
  YELLOW800: "#8c764f",
  YELLOW900: "#6b5a3c",

  // red
  RED50: "#fbeaea",
  RED100: "#f1bfbf",
  RED200: "#eb9f9f",
  RED300: "#e27474",
  RED400: "#dc5959",
  RED500: "#d32f2f", // STATUS ERROR
  RED600: "#c02b2b",
  RED700: "#962121",
  RED800: "#741a1a",
  RED900: "#591414",

  // sunrise
  SUNRISE50: "#fdf0e6",
  SUNRISE100: "#f9d1b1",
  SUNRISE200: "#f7bb8b",
  SUNRISE300: "#f39d55",
  SUNRISE400: "#f18935",
  SUNRISE500: "#ed6c02", // STATUS WARNING
  SUNRISE600: "#d86202",
  SUNRISE700: "#a84d01",
  SUNRISE800: "#823b01",
  SUNRISE900: "#642d01",

  // green
  GREEN50: "#ECFBEC",
  GREEN100: "#DAF3DA",
  GREEN200: "#B1ECB3",
  GREEN300: "#84CD87",
  GREEN400: "#54A858", // STATUS SUCCESS
  GREEN500: "#2E7D32",
  GREEN600: "#206823",
  GREEN700: "#105312",
  GREEN800: "#09370B",
  GREEN900: "#042506",

  // blue
  BLUE50: "#e9f4f8",
  BLUE100: "#bcdeeb",
  BLUE200: "#9ccee1",
  BLUE300: "#6eb8d3",
  BLUE400: "#52aaca",
  BLUE500: "#2795bd", // STATUS INFO
  BLUE600: "#2388ac",
  BLUE700: "#1c6a86",
  BLUE800: "#155268",
  BLUE900: "#103f4f",

  // grey
  GREY900: "#212121", // HEADING
  GREY800: "#424242", // BODY
  GREY700: "#616161", // SUBTITLE
  GREY600: "#757575",
  GREY500: "#9E9E9E", // LIGHT
  GREY400: "#BDBDBD",
  GREY300: "#E0E0E0", // BORDER
  GREY100: "#F5F5F5",
  GREY50: "#fafafa",
};

export interface IbackgroundGradiends {
  MAIN: string;
}

export const backgroundGradiends = {
  MAIN: `linear-gradient(180deg, #FFF6E7 0%, #DAD3FE 100%)`,
};

// Create a theme instance
const theme = createTheme({
  palette: {
    primary: {
      main: customPalette.WHITE,
    },
    secondary: {
      main: "#19857b",
    },
    error: {
      main: red.A400,
    },
    customColors: customPalette,
  },
});

export default theme;

import { URLString } from "./URLString";

export class ConsumerPortalConfig {
  constructor(
    public adRestBaseURL: URLString,
    public farcasterBaseUrl: URLString,
    public ipfsGatewayUrl: URLString,
    public adPortalUrl: URLString,
    public gaId: string,
    public environment: string
  ) {}
}

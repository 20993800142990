export enum EPlacement {
	TOP = "TOP",
	BOTTOM = "BOTTOM",
	CENTER = "CENTER",
	TOP_LEFT = "TOP_LEFT",
	TOP_RIGHT = "TOP_RIGHT",
	BOTTOM_LEFT = "BOTTOM_LEFT",
	BOTTOM_RIGHT = "BOTTOM_RIGHT",
	CENTER_LEFT = "CENTER_LEFT",
	CENTER_RIGHT = "CENTER_RIGHT",
}

import { IConfigProvider } from "@sdl-marketing/objects/interfaces/utilities/IConfigProvider";
import { ConsumerPortalConfig } from "@sdl-marketing/objects/primitives/ConsumerPortalConfig";
import { URLString } from "@sdl-marketing/objects/primitives/URLString";

export class ConfigProvider implements IConfigProvider {
  protected config: ConsumerPortalConfig;
  constructor() {
    this.config = new ConsumerPortalConfig(
      URLString(
        /* "https://public-api.dev.snickerdoodle.dev/" */ "https://public-api.snickerdoodle.com"
      ),
      URLString("https://farcaster.snickerdoodle.com"),
      URLString("https://ipfs-gateway.snickerdoodle.com/ipfs"),
      URLString("https://biz.snickerdoodle.com"),
      "G-3G9XTTPVBH",
      "PROD"
    );
  }

  getConfig(): ConsumerPortalConfig {
    return this.config;
  }
}

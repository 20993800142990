import { FarcasterPublicAdModel } from "@sdl-marketing/models/FarcasterPublicAdModel";
import { PagedResponse } from "@sdl-marketing/objects/business/PagedResponse";
import { IPublicGatewayRepository } from "@sdl-marketing/objects/interfaces/data/IPublicGatewayRepository";
import { IFetchAdapter } from "@sdl-marketing/objects/interfaces/IFetchAdapter";
import { IGetPublicAdsParams } from "@sdl-marketing/objects/interfaces/params/IGetPublicAdsParams";
import { ILoginUrlResult } from "@sdl-marketing/objects/interfaces/utilities/ILoginUrlResult";
import { URLString } from "@sdl-marketing/objects/primitives/URLString";
import { ResultAsync } from "neverthrow";
import { urlJoin } from "url-join-ts";

export class PublicGatewayRepository implements IPublicGatewayRepository {
  protected ajaxUtils: IFetchAdapter;

  public constructor(ajaxUtils: IFetchAdapter) {
    this.ajaxUtils = ajaxUtils;
  }

  public getPublicAds(
    baseUrl: URLString,
    params: IGetPublicAdsParams
  ): ResultAsync<PagedResponse<FarcasterPublicAdModel>, Error> {
    const urlString = urlJoin(baseUrl, "/v0/ads/public");
    return this.ajaxUtils.get<PagedResponse<FarcasterPublicAdModel>>(
      urlString,
      {
        params,
      }
    );
  }

  public loginWithFarcaster(
    baseUrl: URLString
  ): ResultAsync<ILoginUrlResult, Error> {
    const urlString = urlJoin(baseUrl, "/v0/login/farcaster");
    return this.ajaxUtils.get<ILoginUrlResult>(urlString, {
      withCredentials: true,
    });
  }
}

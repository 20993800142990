import {
  EEntriesID,
  EEntriesTags,
} from "@sdl-marketing/objects/enums/EEntries";
import { ERoutes } from "@sdl-marketing/objects/enums/ERoutes";
import { ITag } from "@sdl-marketing/objects/interfaces/entries";
import { IBlog } from "@sdl-marketing/objects/interfaces/entries/IBlog";
import { IEntry } from "@sdl-marketing/objects/interfaces/entries/IEntry";
import { IEvent } from "@sdl-marketing/objects/interfaces/entries/IEvent";
import { IFAQ } from "@sdl-marketing/objects/interfaces/entries/IFaq";
import { IFeature } from "@sdl-marketing/objects/interfaces/entries/IFeature";
import { ISpaceEpisode } from "@sdl-marketing/objects/interfaces/entries/ISpaceEpisode";
import { ISpaceShow } from "@sdl-marketing/objects/interfaces/entries/ISpaceShow";
import {
  EntriesProviderProps,
  IEntriesContext,
} from "@sdl-marketing/objects/types/EntryContextTypes";
import client from "@sdl-marketing/services/contentful/contentful";
import React, { createContext, useState, useContext, useEffect } from "react";
import { useLocation } from "react-router-dom";

const EntriesContext = createContext<IEntriesContext | undefined>(undefined);

export const EntriesProvider: React.FC<EntriesProviderProps> = ({
  children,
}) => {
  const [entries, setEntries] = useState<IEntry[]>([]);
  const [spaceShow, setSpaceShow] = useState<ISpaceShow[]>([]);
  const [spaceEpisode, setSpaceEpisode] = useState<ISpaceEpisode[]>([]);
  const [features, setFeatures] = useState<IFeature[]>([]);
  const [blogs, setBlogs] = useState<IBlog[]>([]);
  const [events, setEvents] = useState<IEvent[]>([]);
  const [faqs, setFAQs] = useState<IFAQ[]>([]);
  const location = useLocation();

  const fetchSpaceEntries = async () => {
    try {
      const response: any = await client.getEntries();
      console.log("Fetched all entries:", response);
      const shows: ISpaceShow[] = [];
      const episodes: ISpaceEpisode[] = [];
      const uniqueShowIds: Set<string> = new Set();
      const uniqueEpisodeIds: Set<string> = new Set();

      response.items.forEach((entry: IEntry) => {
        entry.metadata.tags.forEach((tag: ITag) => {
          if (entry.sys?.contentType?.sys?.id === EEntriesID.SPACESHOW) {
            if (
              tag.sys.id === EEntriesTags.SPACESHOW &&
              !uniqueShowIds.has(entry.sys.id)
            ) {
              uniqueShowIds.add(entry.sys.id);
              shows.push(entry);
            }
          }
          if (entry.sys?.contentType?.sys?.id === EEntriesID.SPACEEPISODE) {
            if (
              tag.sys.id === EEntriesTags.SPACEEPISODE &&
              !uniqueEpisodeIds.has(entry.sys.id)
            ) {
              uniqueEpisodeIds.add(entry.sys.id);
              episodes.push(entry);
            }
          }
        });
      });

      console.log("Shows:", shows);
      console.log("Episodes:", episodes);
      setSpaceShow(shows);
      setSpaceEpisode(episodes);
    } catch (error) {
      console.error("Error fetching entries from Contentful:", error);
    }
  };

  const fetchFeatureEntries = async () => {
    try {
      const response: any = await client.getEntries();
      console.log("Fetched all entries:", response);
      const features: IFeature[] = [];
      const uniqueIds: Set<string> = new Set();

      response.items.forEach((entry: IEntry) => {
        if (entry.sys?.contentType?.sys?.id === EEntriesID.FEATURE) {
          entry.metadata.tags.forEach((tag: ITag) => {
            if (
              tag.sys.id === EEntriesTags.FEATURE &&
              !uniqueIds.has(entry.sys.id)
            ) {
              uniqueIds.add(entry.sys.id);
              features.push(entry);
            }
          });
        }
      });

      console.log("features:", features);
      setFeatures(features);
    } catch (error) {
      console.error("Error fetching entries from Contentful:", error);
    }
  };

  const fetchBlogEntries = async () => {
    try {
      const response: any = await client.getEntries();
      console.log("Fetched all entries:", response);
      const blogs: IBlog[] = [];
      const uniqueBlogIds: Set<string> = new Set();

      response.items.forEach((entry: IEntry) => {
        if (entry.sys?.contentType?.sys?.id === EEntriesID.BLOG) {
          entry.metadata.tags.forEach((tag: ITag) => {
            if (
              tag.sys.id === EEntriesTags.BLOG &&
              !uniqueBlogIds.has(entry.sys.id)
            ) {
              uniqueBlogIds.add(entry.sys.id);
              console.log("Added entry: ", entry);
              blogs.push(entry);
            }
          });
        }
      });

      console.log("blogs:", blogs);
      setBlogs(blogs);
    } catch (error) {
      console.error("Error fetching entries from Contentful:", error);
    }
  };

  const fetchEventEntries = async () => {
    try {
      const response: any = await client.getEntries();
      console.log("Fetched all entries:", response);
      const events: IEvent[] = [];
      const uniqueEventIds: Set<string> = new Set();
      response.items.forEach((entry: IEntry) => {
        if (entry.sys?.contentType?.sys?.id === EEntriesID.EVENT) {
          entry.metadata.tags.forEach((tag: ITag) => {
            if (
              tag.sys.id === EEntriesTags.EVENT &&
              !uniqueEventIds.has(entry.sys.id)
            ) {
              uniqueEventIds.add(entry.sys.id);
              events.push(entry);
            }
          });
        }
      });

      console.log("events:", events);
      setEvents(events);
    } catch (error) {
      console.error("Error fetching entries from Contentful:", error);
    }
  };

  const fetchFAQEntries = async () => {
    try {
      const response: any = await client.getEntries();
      console.log("Fetched all entries:", response);
      const FAQ: IFAQ[] = [];

      response.items.forEach((entry: IEntry) => {
        if (entry.sys?.contentType?.sys?.id === EEntriesID.FAQ) {
          entry.metadata.tags.forEach((tag: ITag) => {
            if (tag.sys.id === EEntriesTags.FAQ) {
              FAQ.push(entry as any);
            }
          });
        }
      });

      console.log("FAQ:", FAQ);
      setFAQs(FAQ);
    } catch (error) {
      console.error("Error fetching entries from Contentful:", error);
    }
  };

  const categorizeRoute = (path: any) => {
    if (path.includes(ERoutes.SPACES_PAGE)) {
      return "SPACES";
    } else if (path.includes(ERoutes.FEATURES_PAGE)) {
      return "FEATURES";
    } else if (path.includes(ERoutes.BLOG_PAGE)) {
      return "BLOG";
    } else if (path.includes(ERoutes.EVENTS_PAGE)) {
      return "EVENTS";
    } else if (path.includes(ERoutes.LANDING_PAGE)) {
      return "";
    }
    return "DEFAULT";
  };

  const controllFetchType = () => {
    const routeCategory = categorizeRoute(location.pathname);

    switch (routeCategory) {
      case "SPACES":
        fetchSpaceEntries();
        break;
      case "FEATURES":
        fetchFeatureEntries();
        break;
      case "BLOG":
        fetchBlogEntries();
        break;
      case "EVENTS":
        fetchEventEntries();
        break;
      case "":
        fetchFAQEntries();
        break;
      default:
        console.log("No specific fetch function for this route");
        break;
    }
  };

  useEffect(() => {
    controllFetchType();
  }, [location.pathname]);

  return (
    <EntriesContext.Provider
      value={{
        entries,
        spaceEpisode,
        spaceShow,
        features,
        blogs,
        events,
        faqs,
      }}
    >
      {children}
    </EntriesContext.Provider>
  );
};

export const useEntries = () => {
  const context = useContext(EntriesContext);
  if (context === undefined) {
    throw new Error("useEntries must be used within an EntriesProvider");
  }
  return context;
};

export enum EFarcasterFrameBadgePosition {
	TOP = 0,
	BOTTOM = 1,
	CENTER = 2,
	TOP_LEFT = 3,
	TOP_RIGHT = 4,
	BOTTOM_LEFT = 5,
	BOTTOM_RIGHT = 6,
	CENTER_LEFT = 7,
	CENTER_RIGHT = 8,
}

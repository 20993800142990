import AdDetail from "@sdl-marketing/components/modals/AdDetail";
import { IAdDetailProps } from "@sdl-marketing/components/modals/AdDetail.interface";
import { EModalSelectors } from "@sdl-marketing/objects/enums/EModalSelectors";
import React, {
  createContext,
  useContext,
  useState,
  FC,
  PropsWithChildren,
  useCallback,
  useMemo,
  useRef,
  useEffect,
} from "react";


type ModalSelectorTypeMap = {
  [EModalSelectors.AD_DETAIL_MODAL]: IAdDetailProps;
};

type ModalSelector = keyof ModalSelectorTypeMap;

export interface IModal<T extends keyof ModalSelectorTypeMap | null> {
  modalSelector: T;
  customProps: T extends keyof ModalSelectorTypeMap
    ? ModalSelectorTypeMap[T]
    : null;
}

const initialModalState: IModal<keyof ModalSelectorTypeMap | null> = {
  modalSelector: null,
  customProps: null,
} as IModal<keyof ModalSelectorTypeMap | null>;

interface ILayoutContext {
  openModal: <T extends ModalSelector | null = null>(
    modalProps: IModal<T>
  ) => void;
  modalState: IModal<ModalSelector>;
  closeModal: (selector?: EModalSelectors) => void;
}

const LayoutContext = createContext<ILayoutContext>({} as ILayoutContext);

export const LayoutProvider: FC<PropsWithChildren> = ({ children }) => {
  const [modalState, setModalState] = useState<IModal<ModalSelector>>(
    initialModalState as IModal<keyof ModalSelectorTypeMap>
  );
  const activeModalRef = useRef<EModalSelectors | null>(null);

  useEffect(() => {
    activeModalRef.current = modalState.modalSelector;
  }, [modalState.modalSelector]);

  const closeModal = useCallback((selector?: EModalSelectors) => {
    if (selector && selector !== activeModalRef.current) return;
    setModalState(initialModalState as IModal<keyof ModalSelectorTypeMap>);
  }, []);

  const openModal = useCallback(
    <T extends ModalSelector | null = null>(modalProps: IModal<T>) => {
      setModalState(modalProps as IModal<ModalSelector>);
    },
    []
  );

  const modalComponent = useMemo(() => {
    switch (modalState.modalSelector) {
      case EModalSelectors.AD_DETAIL_MODAL:
        return <AdDetail />;
      default:
        return null;
    }
  }, [modalState.modalSelector]);

  return (
    <LayoutContext.Provider value={{ openModal, closeModal, modalState }}>
      {modalComponent ? modalComponent : null}
      {children}
    </LayoutContext.Provider>
  );
};

export const useLayoutContext = () => useContext(LayoutContext);

import { FarcasterPublicAdModel } from "@sdl-marketing/models/FarcasterPublicAdModel";
import { PagedResponse } from "@sdl-marketing/objects/business/PagedResponse";
import { IAdRepository } from "@sdl-marketing/objects/interfaces/data/IAdRepository";
import { IPublicGatewayRepository } from "@sdl-marketing/objects/interfaces/data/IPublicGatewayRepository";
import { IFetchAdapter } from "@sdl-marketing/objects/interfaces/IFetchAdapter";
import { IGetPublicAdsParams } from "@sdl-marketing/objects/interfaces/params/IGetPublicAdsParams";
import { ConsumerPortalConfig } from "@sdl-marketing/objects/primitives/ConsumerPortalConfig";
import { ResultAsync } from "neverthrow";


export class AdRepository implements IAdRepository {
  constructor(
    protected config: ConsumerPortalConfig,
    protected ajaxUtils: IFetchAdapter,
    protected publicGatewayRepository: IPublicGatewayRepository
  ) {}

  public getAds(
    params: IGetPublicAdsParams
  ): ResultAsync<PagedResponse<FarcasterPublicAdModel>, Error> {
    return this.publicGatewayRepository.getPublicAds(
      this.config.adRestBaseURL,
      params
    );
  }
}

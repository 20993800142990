import MuiButton, { ButtonProps } from "@mui/material/Button";
import { styled } from "@mui/material/styles";
import theme from "@sdl-marketing/theme/theme";
import React, { FC, useMemo } from "react";

const customColors = [] as const;
const customVariants = ["main", "card", "secondary"] as const;
interface IButtonProps extends Omit<ButtonProps, "color" | "variant"> {
  color?: ButtonProps["color"] | (typeof customColors)[number];
  variant?: ButtonProps["variant"] | (typeof customVariants)[number];
}

const StyledButton = styled(MuiButton)((props) => {
  const _props = props as IButtonProps;
  const colorMap: Record<
    `${(typeof customVariants)[number]}`,
    { bg: string; hover: string; text: string }
  > = {
    main: {
      bg: theme.palette.customColors.MAINPURPLE500,
      hover: theme.palette.customColors.MAINPURPLE400,
      text: theme.palette.customColors.WHITE,
    },
    card: {
      bg: theme.palette.customColors.MAINPURPLE50,
      hover: theme.palette.customColors.MAINPURPLE100,
      text: theme.palette.customColors.MAINPURPLE500,
    },
    secondary: {
      bg: theme.palette.customColors.YELLOW100,
      hover: theme.palette.customColors.YELLOW50,
      text: theme.palette.customColors.MAINPURPLE700,
    },
  };
  const sizeMap: Record<
    `${Exclude<ButtonProps["size"], undefined>}`,
    { height: number; fontSize: number; borderRadius: number }
  > = {
    small: { height: 44, fontSize: 16, borderRadius: 12 },
    medium: { height: 56, fontSize: 18, borderRadius: 14 },
    large: { height: 60, fontSize: 18, borderRadius: 16 },
  };
  const isCustomVariant = (
    variant: any
  ): variant is (typeof customVariants)[number] => {
    return customVariants.includes(variant);
  };

  const { height, fontSize, borderRadius } = sizeMap[_props.size ?? "medium"];
  const { bg, hover, text } = isCustomVariant(_props.variant)
    ? colorMap[_props.variant]
    : colorMap["main"];

  return {
    fontFamily: "Shrikhand",
    textTransform: "none",
    height,
    borderRadius,
    color: text,
    fontSize,
    backgroundColor: bg,
    "&:hover": {
      backgroundColor: hover,
      boxShadow: "none",
    },
    "&:active": {
      boxShadow: "none",
    },
    "&:focus": {
      backgroundColor: bg,
    },
    [props.theme.breakpoints.between("sm", "md")]: {
      fontSize: fontSize * 0.9,
    },
    [props.theme.breakpoints.down("sm")]: {
      fontSize: 14,
    },
  };
});

const validMuiColors:
  | string
  | any[]
  | readonly [
      "inherit",
      "primary",
      "secondary",
      "success",
      "error",
      "info",
      "warning",
      any
    ] = [
  "inherit",
  "primary",
  "secondary",
  "success",
  "error",
  "info",
  "warning",
  undefined,
] as const;

const validMuiVariants:
  | string
  | any[]
  | readonly ["text", "outlined", "contained", any] = [
  "text",
  "outlined",
  "contained",
  undefined,
] as const;

export const ModalButton: FC<IButtonProps> = ({ color, variant, ...rest }) => {
  const isMuiButton = useMemo(
    () =>
      validMuiColors.includes(color as (typeof validMuiColors)[number]) &&
      validMuiVariants.includes(variant as (typeof validMuiVariants)[number]),
    []
  );
  return (
    <>
      {isMuiButton ? (
        <MuiButton {...rest} />
      ) : (
        <StyledButton
          color={color as ButtonProps["color"]}
          variant={variant as ButtonProps["variant"]}
          {...rest}
        />
      )}
    </>
  );
};

import { SvgString } from "@sdl-marketing/objects/primitives/SvgString";
import { ComponentType, ContainerProps, ExternalSVGProps, FlexBoxProps, GridProps, ImageProps, MarginProperties, RootContainer, TextProps } from "../interfaces/SvgComponentProperties";
import { EComponent } from "@sdl-marketing/objects/enums/EComponent";
import { EPlacement } from "@sdl-marketing/objects/enums/EPlacement";
import { EAlignment } from "@sdl-marketing/objects/enums/EAlignment";
import { EJustify } from "@sdl-marketing/objects/enums/EJustify";
import { EFontFamily } from "@sdl-marketing/objects/enums/EFontFamily";
import { EFontWeight } from "@sdl-marketing/objects/enums/EFontWeight";
import { TextUtils } from "./TextUtils";


interface IMetaData {
  width: number;
  height: number;
}

interface IRenderFnReturn {
  content: string;
  contentMetadata: IMetaData;
}
export class SvgBuilder {
  constructor(private root: RootContainer) {}

  public getSVGString(): SvgString {
    const { width, height } = this.root;
    const svgContent = this.renderContainer(
      {
        ...this.root,
        type: EComponent.Container,
      },
      0,
      0,
      width
    )?.content;

    const result = `<svg width="${width}" height="${height}"  xmlns="http://www.w3.org/2000/svg" viewBox="0 0 ${width} ${height}">${svgContent}</svg>`;
    return SvgString(result);
  }

  private getRootInfo(): IMetaData & { padding: number } {
    const { width, height, padding } = this.root;
    return { width, height, padding };
  }

  // Get the cursor position for the placement inside the root container
  private getCursorForPlacement(
    itemMeta: IMetaData,
    placement: EPlacement
  ): {
    x: number;
    y: number;
  } {
    const { width, height, padding } = this.getRootInfo();
    let x = (width - itemMeta.width) / 2;
    let y = padding;

    if (placement.includes("RIGHT")) {
      x = width - itemMeta.width - padding;
    } else if (placement.includes("LEFT")) {
      x = padding;
    }

    if (placement.includes("BOTTOM")) {
      y = height - itemMeta.height - padding;
    } else if (placement.includes("CENTER")) {
      y = (height - itemMeta.height) / 2;
    }

    return { x, y };
  }

  private renderComponent(
    item: ComponentType,
    currentX: number,
    currentY: number,
    availableWidth: number
  ): IRenderFnReturn {
    switch (item.type) {
      case EComponent.Container:
        return this.renderContainer(item, currentX, currentY, availableWidth);
      case EComponent.FlexBox:
        return this.renderFlexBox(item, currentX, currentY, availableWidth);
      case EComponent.Grid:
        return this.renderGrid(item, currentX, currentY, availableWidth);
      case EComponent.Text:
        return this.renderText(item, currentX, currentY, availableWidth);
      case EComponent.Image:
        return this.renderImage(item, currentX, currentY, availableWidth);
      case EComponent.ExternalSVG:
        return this.renderExternalSVG(item, currentX, currentY, availableWidth);
      default:
        throw new Error("Invalid component type");
    }
  }

  private renderContainer(
    item: ContainerProps,
    currentX: number,
    currentY: number,
    availableWidth: number
  ): IRenderFnReturn {
    const {
      bgImage,
      blurBgImage,
      blurRadius = 30,
      bgColor = "#000",
      bgOpacity = 1,
      borderRadius = 0,
      height,
      padding,
      margin,
      children,
      alignSelf,
    } = item;

    let { width } = item;

    if (width > availableWidth) {
      width = availableWidth;
    }

    const { mt, mb, ml, mr } = this.getMargins(margin);
    let x = currentX;

    if (alignSelf === EAlignment.CENTER) {
      x = currentX + (availableWidth - width) / 2;
    } else if (alignSelf === EAlignment.RIGHT) {
      x = currentX + availableWidth - width;
    }

    const childX = x + padding + ml;

    const initialY = currentY + padding + mt;
    const childrenMaxWidth = width - padding * 2 - ml - mr;

    let childY = initialY;
    let totalChildrenHeight = 0;

    const childrenContent = children.map((child) => {
      const calculedChild = this.renderComponent(
        child,
        childX,
        childY,
        childrenMaxWidth
      );

      childY += calculedChild.contentMetadata.height;
      totalChildrenHeight += calculedChild.contentMetadata.height;
      return calculedChild.content;
    });

    // Calculate container height if set to 'fit-content'
    const containerHeight =
      height === "fit-content" ? totalChildrenHeight + padding * 2 : height;

    return {
      content: `<g>
        <defs>
        <filter id="blur" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
        <feGaussianBlur stdDeviation="${blurRadius} ${blurRadius}" edgeMode="duplicate" />
        <feComponentTransfer>
          <feFuncA type="discrete" tableValues="1 1" />
        </feComponentTransfer>
        </filter>
        </defs>
        ${
          bgImage
            ? `<image href="${bgImage}" preserveAspectRatio="xMidYMid slice" x="0" y="0" width="${width}" height="${height}" ${
                blurBgImage ? `filter="url(#blur)"` : ""
              } />`
            : ""
        }
        <rect rx="${borderRadius / 2}"  ry="${borderRadius / 2}" x="${
        x + ml
      }" y="${
        mt + currentY
      }" width="${width}" height="${containerHeight}" fill="${bgColor}" opacity="${bgOpacity}"  />
      
              ${childrenContent.join("")}
          </g>`,
      contentMetadata: {
        width: width + ml + mr,
        height: containerHeight + mb + mt,
      },
    };
  }

  private renderFlexBox(
    item: FlexBoxProps,
    currentX: number,
    currentY: number,
    availableWidth: number
  ): IRenderFnReturn {
    const {
      bgColor = "transparent",
      absolutePosition,
      bgOpacity = 1,
      borderRadius = 0,
      children,
      margin,
      justifyContent = EJustify.CENTER,
      alignSelf = EAlignment.LEFT,
      width,
      pr = 0,
      pb = 0,
      pt = 0,
      pl = 0,
    } = item;

    const { mt, mb, ml, mr } = this.getMargins(margin);

    const y = mt + pt;
    // initial x position
    let x = ml + pl;
    // initial total width of children
    let childTotalWidth = 0;

    const renderableWidth = availableWidth - pl - pr - ml - mr;

    const renders = children.map((child) => {
      const render = this.renderComponent(
        child,
        x,
        y,
        renderableWidth - childTotalWidth
      );
      x += render.contentMetadata.width;
      childTotalWidth += render.contentMetadata.width;
      return render;
    });

    const childMaxHeight = Math.max(
      ...renders.map((r) => r.contentMetadata.height)
    );

    // Vertically align children
    const vertiacallyAlignedChildren = renders.map((render) => {
      const yOffset = this.getYOffset(
        childMaxHeight,
        render.contentMetadata.height,
        justifyContent
      );
      const updatedContent = this.addYOffset(render.content, yOffset);
      return {
        ...render,
        content: updatedContent,
      };
    });

    // final dimensions
    let totalWidth =
      width === "fill-available"
        ? availableWidth
        : childTotalWidth + pl + pr + ml + mr;
    let totalHeight = childMaxHeight + pt + pb + mt + mb;

    currentX = this.getCursorX(currentX, availableWidth, totalWidth, alignSelf);

    // reposition children based on absoulte position if needed

    if (absolutePosition) {
      const { x, y } = this.getCursorForPlacement(
        { width: totalWidth, height: totalHeight },
        absolutePosition
      );

      // reset x and y
      currentX = x;
      currentY = y;

      // reset total width and height to 0 to removing item from the parent it's in
      totalWidth = 0;
      totalHeight = 0;
    }

    return {
      // render as inner svg to make repositioning easier
      content: `<svg x="${currentX}" y="${currentY}">
              <rect rx="${borderRadius / 2}" ry="${
        borderRadius / 2
      }" x="${ml}" y="${mt}" width="${
        width === "fill-available" ? renderableWidth : childTotalWidth + pl + pr
      }" height="${
        childMaxHeight + pt + pb
      }" fill="${bgColor}" opacity="${bgOpacity}" />
              ${vertiacallyAlignedChildren.map((r) => r.content).join("")}
          </svg>`,
      contentMetadata: {
        width: totalWidth,
        height: totalHeight,
      },
    };
  }

  private renderGrid(
    item: GridProps,
    currentX: number,
    currentY: number,
    availableWidth: number
  ): IRenderFnReturn {
    const {
      columns,
      gap,
      children,
      margin,
      justifyRows = EJustify.START,
    } = item;
    const { mr, ml, mb, mt } = this.getMargins(margin);

    // Set default fractions if not provided
    const fractions =
      columns.fractions ?? (Array(columns.count).fill(1) as [number]);

    // Calculate the column widths based on fractions
    const totalFractions = fractions.reduce(
      (sum, fraction) => sum + fraction,
      0
    );

    // Calculate the width of each unit based on the available width
    const unitWidth =
      (availableWidth - mr - ml - gap * (columns.count - 1)) /
      (totalFractions || 1);

    // Initialize the current x and y positions for the first child
    let x = currentX + ml;
    let y = currentY + mt;
    let currentRowHeight = 0;

    // Initialize total height with the top margin
    let totalHeight = mt;

    const rows: IRenderFnReturn[][] = [];
    let currentRow: IRenderFnReturn[] = [];

    // Collect children into rows
    children.forEach((child, index) => {
      const col = index % columns.count;

      if (col === 0 && currentRow.length > 0) {
        rows.push(currentRow);
        currentRow = [];
        y += currentRowHeight + gap;
        totalHeight += currentRowHeight + gap;
        x = currentX + ml;
        currentRowHeight = 0;
      }

      const childRender = this.renderComponent(
        child,
        x,
        y,
        unitWidth * fractions[col]
      );
      x += unitWidth * fractions[col] + gap;
      currentRowHeight = Math.max(
        currentRowHeight,
        childRender.contentMetadata.height
      );
      currentRow.push(childRender);
    });

    // Add the last row if it contains any elements
    if (currentRow.length > 0) {
      rows.push(currentRow);
      totalHeight += currentRowHeight;
    }

    // Generate content with vertical alignment
    const childrenContent = rows
      .map((row) => {
        const rowHeight = Math.max(...row.map((r) => r.contentMetadata.height));
        return row
          .map((child) => {
            const yOffset = this.getYOffset(
              rowHeight,
              child.contentMetadata.height,
              justifyRows
            );

            // reposition child based on the yOffset
            const updatedContent = this.addYOffset(child.content, yOffset);

            return {
              ...child,
              content: updatedContent,
            }.content;
          })
          .join("");
      })
      .join("");

    // Finally add margin bottom to the total height
    totalHeight += mb;

    // Render as a group
    const res = `<g>${childrenContent} </g> `;

    return {
      content: res,
      contentMetadata: {
        width: availableWidth,
        height: totalHeight,
      },
    };
  }

  private renderText(
    item: TextProps,
    currentX: number,
    currentY: number,
    availableWidth: number
  ): IRenderFnReturn {
    const {
      content,
      fontSize = 20,
      color = "#fff",
      fontFamily = EFontFamily.ChakraPetch,
      fontWeight = EFontWeight.Medium,
      alignSelf = EAlignment.LEFT,
      textAlign = EAlignment.LEFT,
    } = item;

    const { mt, mb, ml, mr } = this.getMargins(item.margin);

    const renderableWidth = availableWidth - ml - mr;
    const lineX = currentX + ml;

    const { lines, lineHeight, totalHeight, totalWidth, ascent } =
      TextUtils.getWrappedTextWithInfo(
        content,
        renderableWidth,
        fontSize,
        fontFamily,
        fontWeight
      );

    const textSvg = lines
      .map((line, index) => {
        return `<text x="${this.getCursorX(
          this.getCursorX(lineX, availableWidth, totalWidth, alignSelf),
          totalWidth,
          line.width,
          textAlign
        )}" y="${
          index * fontSize * lineHeight + fontSize * ascent + currentY + mt
        }"  font-size="${fontSize}" font-family="${fontFamily}" font-weight="${fontWeight}"  fill="${color}">${
          line.content
        }</text>`;
      })
      .join("");

    return {
      content: `<g>${textSvg}</g>`,
      contentMetadata: {
        width: totalWidth + ml + mr,
        height: totalHeight + mt + mb,
      },
    };
  }

  private renderImage(
    item: ImageProps,
    currentX: number,
    currentY: number,
    availableWidth: number
  ): IRenderFnReturn {
    const { src, alignSelf, margin } = item;
    let { width, height } = item;
    const { mr, ml, mb, mt } = this.getMargins(margin);
    let x = currentX;

    // Adjust image width and height to fit available width
    if (availableWidth < width + ml + mr) {
      height = height * ((availableWidth - mr - ml) / width);
      width = availableWidth - mr - ml;
    }

    // set x position based on align
    if (alignSelf === EAlignment.CENTER) {
      x = currentX + (availableWidth - width) / 2;
    } else if (alignSelf === EAlignment.RIGHT) {
      x = currentX + availableWidth - width;
    }

    // Calculate total height and width
    const totalHeight = height + mt + mb;
    const totalWidth = width + ml + mr;

    // object-fit: cover
    return {
      content: `
      <image x="${mr + x}" preserveAspectRatio="xMidYMid slice" y="${
        currentY + mt
      }" width="${width}" height="${height}" href="${src}" />
     `,
      contentMetadata: {
        width: totalWidth,
        height: totalHeight,
      },
    };
  }

  private renderExternalSVG(
    item: ExternalSVGProps,
    currentX: number,
    currentY: number,
    availableWidth: number
  ): IRenderFnReturn {
    const { alignSelf, margin } = item;
    const { mr, ml, mb, mt } = this.getMargins(margin);
    let x = currentX;
    let { content } = item;

    let { width: svgWidth, height: svgHeight } = this.parseSVGMetadata(content);

    if (availableWidth < svgWidth + ml + mr) {
      const scaleFactor = (availableWidth - mr - ml) / svgWidth;
      svgHeight = svgHeight * ((availableWidth - mr - ml) / svgWidth);
      svgWidth = availableWidth - mr - ml;
      // TODO: Adjust SVG content to fit new width and height
      content = content.replace(/<svg[^>]*>/, (match) => {
        // Add or modify width and height attributes
        let modifiedSVG = match
          .replace(/width="[^"]*"/, `width="${svgWidth}"`)
          .replace(/height="[^"]*"/, `height="${svgHeight}"`);

        // If width or height attributes are not present, add them
        if (!/width="[^"]*"/.test(modifiedSVG)) {
          modifiedSVG = modifiedSVG.replace("<svg", `<svg width="${svgWidth}"`);
        }
        if (!/height="[^"]*"/.test(modifiedSVG)) {
          modifiedSVG = modifiedSVG.replace(
            "<svg",
            `<svg height="${svgHeight}"`
          );
        }

        // Add or modify viewBox attribute
        if (!/viewBox="[^"]*"/.test(modifiedSVG)) {
          const originalWidth = svgWidth / scaleFactor;
          const originalHeight = svgHeight / scaleFactor;
          modifiedSVG = modifiedSVG.replace(
            "<svg",
            `<svg viewBox="0 0 ${originalWidth} ${originalHeight}"`
          );
        }

        return modifiedSVG;
      });
    }

    if (alignSelf === EAlignment.CENTER) {
      x = currentX + (availableWidth - svgWidth) / 2;
    } else if (alignSelf === EAlignment.RIGHT) {
      x = currentX + availableWidth - svgWidth;
    }

    return {
      content: content.replace(
        /<svg/,
        `<svg x="${x + ml}" y="${currentY + mt}"`
      ),
      contentMetadata: {
        width: svgWidth + ml + mr,
        height: svgHeight + mt + mb,
      },
    };
  }

  private parseExternalSrcs(
    content: string
  ): { src: string; x: number; y: number; width: number; height: number }[] {
    // Regex to find all 'image' tags in the SVG content
    const imageRegex = /<image[^>]*>/g;

    // Find all 'image' tags in the SVG content
    const imageTags = content.match(imageRegex) ?? [];

    // Parse the 'x', 'y', 'width', and 'height' attributes from each 'image' tag
    const externalSrcs = imageTags.map((tag) => {
      const xMatch = tag.match(/x="([\d.-]+)"/);
      const yMatch = tag.match(/y="([\d.-]+)"/);
      const widthMatch = tag.match(/width="([\d.-]+)"/);
      const heightMatch = tag.match(/height="([\d.-]+)"/);
      const hrefMatch = tag.match(/href="([^"]+)"/);
      return {
        src: hrefMatch?.[1] ?? "",
        x: parseFloat(xMatch?.[1] ?? "0"),
        y: parseFloat(yMatch?.[1] ?? "0"),
        width: parseFloat(widthMatch?.[1] ?? "0"),
        height: parseFloat(heightMatch?.[1] ?? "0"),
      };
    });

    return externalSrcs;
  }

  private parseSVGMetadata(svg: string): { width: number; height: number } {
    const widthMatch = svg.match(/width="(\d+)"/);
    const heightMatch = svg.match(/height="(\d+)"/);
    return {
      width: parseFloat(widthMatch?.[1] ?? "0"),
      height: parseFloat(heightMatch?.[1] ?? "0"),
    };
  }

  private getCursorX(
    currentX: number,
    availableWidth: number,
    width: number,
    alignment: EAlignment
  ) {
    let x = currentX;
    if (alignment === EAlignment.CENTER) {
      x = currentX + (availableWidth - width) / 2;
    } else if (alignment === EAlignment.RIGHT) {
      x = currentX + availableWidth - width;
    }
    return x;
  }

  // helper function to set defaults and get margin values
  private getMargins(margin?: MarginProperties): {
    mt: number;
    mb: number;
    ml: number;
    mr: number;
  } {
    return {
      mt: margin?.mt ?? 0,
      mb: margin?.mb ?? 0,
      ml: margin?.ml ?? 0,
      mr: margin?.mr ?? 0,
    };
  }

  // helper function to get the offset for vertical alignment
  private getYOffset(
    containerHeight: number,
    itemHeight: number,
    placement: EJustify
  ) {
    let yOffset = 0;
    if (placement === EJustify.CENTER) {
      yOffset = (containerHeight - itemHeight) / 2;
    } else if (placement === EJustify.END) {
      yOffset = containerHeight - itemHeight;
    }
    return yOffset;
  }
  // helper function to add an offset to the 'y' attribute of an SVG element
  private addYOffset(element: string, offset: number): string {
    // Function to add offset to y attribute in a specific string
    const modifyYAttributes = (str: string, offset: number) => {
      return str.replace(/y="([\d.-]+)"/g, (match: any, p1: string) => {
        const currentY = parseFloat(p1);
        const newY = currentY + offset;
        return `y="${newY}"`;
      });
    };

    // Regex to find all nested SVG elements
    const nestedSvgRegex = /<svg[^>]*>[\s\S]*?<\/svg>/g;

    // Find and modify nested SVG elements
    const nestedSvgElements: string[] = [];
    element = element.replace(nestedSvgRegex, (match) => {
      // Modify the root of the nested SVG only
      const modifiedNestedSvg = match.replace(
        /(<svg[^>]*y=")([\d.-]+)(")/,
        (m, p1, p2, p3) => {
          const currentY = parseFloat(p2);
          const newY = currentY + offset;
          return `${p1}${newY}${p3}`;
        }
      );
      nestedSvgElements.push(modifiedNestedSvg);
      return `<!-- nested_svg_placeholder_${nestedSvgElements.length - 1} -->`;
    });

    // Modify the root SVG element's y attributes
    let modifiedSvgString = modifyYAttributes(element, offset);

    // Restore all nested SVG elements
    nestedSvgElements.forEach((nestedSvg, index) => {
      modifiedSvgString = modifiedSvgString.replace(
        `<!-- nested_svg_placeholder_${index} -->`,
        nestedSvg
      );
    });

    return modifiedSvgString;
  }

  // TODO
  private validateExternalSVG(content: string) {
    // Validate the SVG content to ensure it is safe to render
    throw new Error("Method not implemented.");
  }
}

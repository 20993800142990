
import { FarcasterPublicAdModel } from "@sdl-marketing/models/FarcasterPublicAdModel";
import { PagedResponse } from "@sdl-marketing/objects/business/PagedResponse";
import { IAdService } from "@sdl-marketing/objects/interfaces/business/IAdService";
import { IAdRepository } from "@sdl-marketing/objects/interfaces/data/IAdRepository";
import { IGetPublicAdsParams } from "@sdl-marketing/objects/interfaces/params/IGetPublicAdsParams";
import { ResultAsync, okAsync } from "neverthrow";

export class AdService implements IAdService {
  private apiCache: Map<string, PagedResponse<FarcasterPublicAdModel>> =
    new Map();
  constructor(protected adRepository: IAdRepository) {}

  public getAds(
    params: IGetPublicAdsParams
  ): ResultAsync<PagedResponse<FarcasterPublicAdModel>, Error> {
    const cacheId = this.getCacheId(params);
    const cachedResult = this.apiCache.get(cacheId);
    if (cachedResult) {
      return okAsync(cachedResult as PagedResponse<FarcasterPublicAdModel>);
    }
    return this.adRepository.getAds(params).map((response) => {
      this.apiCache.set(cacheId, response);
      return response;
    });
  }
  private getCacheId(params: IGetPublicAdsParams): string {
    return JSON.stringify(params);
  }
}

import React, { FC, useCallback, useMemo, useState } from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { FarcasterPublicAdModel } from "@sdl-marketing/models/FarcasterPublicAdModel";
import { useStoreContext } from "@sdl-marketing/contexts/StoreContext";
import { EColorMode } from "@sdl-marketing/objects/enums/EColorMode";
import { EPlacement } from "@sdl-marketing/objects/enums/EPlacement";
import { formatPrice } from "@sdl-marketing/utils/FrameUtils";
import { EFarcasterFrameBadgePosition } from "@sdl-marketing/objects/enums/EFarcasterFrameBadgePosition";
import { EFarcasterFrameSize } from "@sdl-marketing/objects/enums/EFarcasterFrameSize";
import { FarcasterPublicAdRequirementModel } from "@sdl-marketing/models/FarcasterPublicAdRequirementModel";
import { EFarcasterAdRequirementType } from "@sdl-marketing/objects/enums/EFarcasterAdRequirementType";
import { FrameImageParamsFactory } from "@sdl-marketing/mediautils/implementations/FrameImageParamsFactory";
import { SvgBuilder } from "@sdl-marketing/mediautils/implementations/SvgBuilder";
import { SvgRenderer } from "../svgrenderer/SvgRenderer";
import { URLString } from "@sdl-marketing/objects/primitives/URLString";
import theme from "@sdl-marketing/theme/theme";
import { QuestionnaireQuestionModel } from "@sdl-marketing/models/QuestionnaireQuestionModel";
import { EQuestionnaireQuestionDisplayType } from "@sdl-marketing/objects/enums/EQuestionnaire";

interface IItemsToRender {
  image: JSX.Element | null;
  buttons: IFrameButtonProps[];
}
interface IFramePreviewProps {
  ad: FarcasterPublicAdModel;
  onPreviewComplete: () => void;
}

enum EFrameStepType {
  RULE = "RULE",
  SLIDE = "SLIDE",
  SURVEY_QUESTION = "SURVEY_QUESTION",
  SURVEY_COVER = "SURVEY_COVER",
}

type TStep = {
  type: EFrameStepType;
  index: number;
  total: number;
};

interface IItemsToRender {
  image: JSX.Element | null;
  buttons: IFrameButtonProps[];
}

export const FramePreview: FC<IFramePreviewProps> = ({
  ad,
  onPreviewComplete,
}) => {
  const { apiGateway } = useStoreContext();
  const [currentStep, setCurrentStep] = useState<TStep>(
    !!ad.adSurvey
      ? { type: EFrameStepType.SURVEY_COVER, index: 0, total: 1 }
      : {
          type: EFrameStepType.SLIDE,
          index: 0,
          total: ad.slideImages.length,
        }
  );

  const { bountyBadgeParams, isWide } = useMemo(() => {
    let _bountyBadgeParams: null | [string, EColorMode, EPlacement] = null;
    if (ad.bountyBadgeColorMode != null || ad.bountyBadgePosition != null) {
      _bountyBadgeParams = [
        `Claim ${formatPrice(ad.interactorViewPrice)} ${ad.reward.name}`,
        ad.bountyBadgeColorMode != null
          ? ad.bountyBadgeColorMode
          : EColorMode.LIGHT,
        ad.bountyBadgePosition != null
          ? EPlacement[
              EFarcasterFrameBadgePosition[
                ad.bountyBadgePosition
              ] as keyof typeof EPlacement
            ]
          : EPlacement.BOTTOM,
      ];
    }
    return {
      bountyBadgeParams: _bountyBadgeParams,
      isWide: ad.size === EFarcasterFrameSize.Landscape,
    };
  }, [ad]);

  const getRequirementContentAndButton = useCallback(
    (
      requirement: FarcasterPublicAdRequirementModel
    ): {
      actionButton: IFrameButtonProps;
      content: [string, string];
    } => {
      switch (requirement.type) {
        case EFarcasterAdRequirementType.VisitLink: {
          return {
            actionButton: {
              label: "Visit Link",
              onClick: () => {
                window.open(
                  requirement.visitLink || "https://www.snickerdoodle.com/",
                  "_blank"
                );
              },
              externalLink: true,
            },
            content: [
              "Visit Link to Claim",
              requirement.visitLink || "https://www.snickerdoodle.com/",
            ],
          };
        }
        case EFarcasterAdRequirementType.RecastCast: {
          return {
            actionButton: {
              label: "Re-Cast",
              onClick: () => {
                window.open(
                  requirement.recastCastUrl || "https://www.snickerdoodle.com/",
                  "_blank"
                );
              },
              externalLink: true,
            },
            content: [
              "Re-Cast to Claim",
              requirement.recastCastUrl || "https://www.snickerdoodle.com/",
            ],
          };
        }
        case EFarcasterAdRequirementType.LikeCast: {
          return {
            actionButton: {
              label: "Like Cast",
              onClick: () => {
                window.open(
                  requirement.likeCastUrl || "https://www.snickerdoodle.com/",
                  "_blank"
                );
              },
              externalLink: true,
            },
            content: [
              "Like to Claim",
              requirement.likeCastUrl || "https://www.snickerdoodle.com/",
            ],
          };
        }
        case EFarcasterAdRequirementType.FollowWarpcastUser: {
          return {
            actionButton: {
              onClick: () => {
                window.open(
                  `https://warpcast.com/${requirement.followWarpcastUserName}`,
                  "_blank"
                );
              },
              externalLink: true,
              label: "Follow User",
            },
            content: [
              "Follow to Claim",
              `@${requirement.followWarpcastUserName}`,
            ],
          };
        }
        case EFarcasterAdRequirementType.FollowWarpcastChannel: {
          return {
            actionButton: {
              label: "Follow Channel",
              onClick: () => {
                window.open(
                  `https://warpcast.com/~/channel/${requirement.followWarpcastChannelId}`,
                  "_blank"
                );
              },
              externalLink: true,
            },
            content: [
              "Follow to Claim",
              `/${requirement.followWarpcastChannelId}`,
            ],
          };
        }
      }
    },
    []
  );

  const itemsToRender: IItemsToRender = useMemo(() => {
    switch (currentStep.type) {
      case EFrameStepType.RULE: {
        const requirementContent = getRequirementContentAndButton(
          ad.requirements[currentStep.index]
        );
        const res: IItemsToRender = {
          image: (
            <SvgRenderer
              svg={new SvgBuilder(
                FrameImageParamsFactory.getRuleFrameParams(
                  URLString(
                    ad.adImage
                      ? `${apiGateway.config.ipfsGatewayUrl}/${ad.adImage}`
                      : ""
                  ),
                  ...requirementContent.content,
                  ad.requirements[currentStep.index].imageUrl !== null
                    ? ad.requirements[currentStep.index].imageUrl!
                    : undefined,
                  false,
                  currentStep.total > 1
                    ? {
                        currentStepIndex: currentStep.index,
                        steps: currentStep.total,
                      }
                    : undefined,
                  isWide
                )
              ).getSVGString()}
            />
          ),
          buttons: [requirementContent.actionButton],
        };
        if (currentStep.index <= ad.requirements.length - 1) {
          res.buttons.push({
            label:
              currentStep.index === ad.requirements.length - 1
                ? "Share"
                : "Next Requirement",
            onClick: () => {
              if (currentStep.index === ad.requirements.length - 1) {
                onPreviewComplete();
              } else {
                setCurrentStep((prev) => ({
                  ...prev,
                  index: currentStep.index + 1,
                }));
              }
            },
          });
        }
        return res;
      }
      case EFrameStepType.SLIDE: {
        const res: IItemsToRender = {
          image: bountyBadgeParams ? (
            <SvgRenderer
              svg={new SvgBuilder(
                FrameImageParamsFactory.getImageWithBadgeParams(
                  URLString(
                    `${apiGateway.config.ipfsGatewayUrl}/${
                      ad.slideImages?.[currentStep.index]?.cid ?? ad.adImage
                    }`
                  ),
                  ...bountyBadgeParams,
                  isWide
                )
              ).getSVGString()}
            />
          ) : (
            <Box
              component="img"
              width="100%"
              height="auto"
              sx={{
                aspectRatio: isWide ? "1.91" : "1.0",
                objectFit: "cover",
              }}
              src={`${apiGateway.config.ipfsGatewayUrl}/${
                ad.slideImages?.[currentStep.index]?.cid ?? ad.adImage
              }`}
            />
          ),
          buttons: [
            {
              label: ad.callToAction,
              externalLink: true,
              onClick: () => {
                window.open(ad.callToActionUrl, "_blank");
              },
            },
          ],
        };
        if (
          ad.slideImages.length === 1 ||
          currentStep.index === ad.slideImages.length - 1 ||
          ad.slideImages.length === 0
        ) {
          res.buttons.push({
            label: "Claim Reward",
            onClick: () => {
              if (ad.requirements?.length > 0) {
                setCurrentStep({
                  type: EFrameStepType.RULE,
                  index: 0,
                  total: ad.requirements.length,
                });
              } else {
                onPreviewComplete();
              }
            },
          });
        }
        if (
          ad.slideImages.length > 1 &&
          currentStep.index > 0 &&
          currentStep.index < ad.slideImages.length - 1
        ) {
          res.buttons.push({
            label: "Back",
            onClick: () => {
              setCurrentStep((prev) => ({
                ...prev,
                index: currentStep.index - 1,
              }));
            },
          });
        }
        if (
          ad.slideImages.length > 1 &&
          currentStep.index < ad.slideImages.length - 1
        ) {
          res.buttons.push({
            label: "Next",
            onClick: () => {
              setCurrentStep((prev) => ({
                ...prev,
                index: currentStep.index + 1,
              }));
            },
          });
        }
        return res;
      }
      case EFrameStepType.SURVEY_COVER: {
        const res: IItemsToRender = {
          image: ad.adImage ? (
            <>
              {bountyBadgeParams ? (
                <SvgRenderer
                  svg={new SvgBuilder(
                    FrameImageParamsFactory.getImageWithBadgeParams(
                      URLString(
                        `${apiGateway.config.ipfsGatewayUrl}/${
                          ad.slideImages?.[currentStep.index]?.cid ?? ad.adImage
                        }`
                      ),
                      ...bountyBadgeParams,
                      isWide
                    )
                  ).getSVGString()}
                />
              ) : (
                <Box
                  component="img"
                  width="100%"
                  height="auto"
                  sx={{
                    aspectRatio: isWide ? "1.91" : "1.0",
                    objectFit: "cover",
                  }}
                  src={`${apiGateway.config.ipfsGatewayUrl}/${
                    ad.slideImages?.[currentStep.index]?.cid ?? ad.adImage
                  }`}
                />
              )}
            </>
          ) : (
            <SvgRenderer
              svg={new SvgBuilder(
                FrameImageParamsFactory.getSurveyCoverFrameParams(
                  ad.adSurvey!.coverText || "survey",
                  ad.adSurvey!.coverFontColor
                    ? `#${ad.adSurvey!.coverFontColor}`
                    : undefined,
                  ad.adSurvey!.coverBGColor
                    ? `#${ad.adSurvey!.coverBGColor}`
                    : undefined,
                  `Claim ${formatPrice(ad.interactorViewPrice)} ${
                    ad.reward.name
                  }`,
                  ad.bountyBadgeColorMode != null
                    ? ad.bountyBadgeColorMode
                    : EColorMode.LIGHT,
                  ad.bountyBadgePosition != null
                    ? EPlacement[
                        EFarcasterFrameBadgePosition[
                          ad.bountyBadgePosition
                        ] as keyof typeof EPlacement
                      ]
                    : EPlacement.BOTTOM,
                  isWide
                )
              ).getSVGString()}
            />
          ),

          buttons: [
            {
              label: "Start Survey",
              onClick: () => {
                setCurrentStep({
                  type: EFrameStepType.SURVEY_QUESTION,
                  index: 0,
                  total: ad.adSurvey?.questionnaire?.questions.length || 0,
                });
              },
            },
          ],
        };
        return res;
      }
      case EFrameStepType.SURVEY_QUESTION: {
        const _question =
          ad.adSurvey?.questionnaire?.questions?.[currentStep.index];
        const question = _question as QuestionnaireQuestionModel;
        const isLastQuestion = currentStep.index === currentStep.total - 1;
        const doesHaveRules = ad.requirements.length > 0;

        const res: IItemsToRender = {
          image: (
            <SvgRenderer
              svg={new SvgBuilder(
                FrameImageParamsFactory.getSurveyQuestionFrameParams(
                  question.question,
                  question.options.map((option) => option.option),
                  undefined,

                  undefined,
                  undefined,
                  question.displayType ===
                    EQuestionnaireQuestionDisplayType.Scale,
                  false,
                  undefined,
                  isWide
                )
              ).getSVGString()}
            />
          ),
          buttons: [
            {
              label: isLastQuestion && !doesHaveRules ? "Share" : "Next",
              onClick: () => {
                if (isLastQuestion) {
                  if (doesHaveRules) {
                    setCurrentStep({
                      type: EFrameStepType.RULE,
                      index: 0,
                      total: ad.requirements.length,
                    });
                  } else {
                    onPreviewComplete();
                  }
                } else {
                  setCurrentStep((prev) => ({
                    ...prev,
                    index: currentStep.index + 1,
                  }));
                }
              },
            },
          ],
        };
        return res;
      }
    }
  }, [currentStep, bountyBadgeParams, isWide, ad]);

  return (
    <Box
      display="flex"
      border="1px solid rgba(0, 0, 0, 0.15)"
      flexDirection="column"
      mx={0.75}
      sx={{
        borderTopLeftRadius: 8,
        borderTopRightRadius: 8,
        borderBottomLeftRadius: 12,
        borderBottomRightRadius: 12,
        overflow: "hidden",
      }}
    >
      {itemsToRender.image}
      <Box px={2} py={1} gap={1} bgcolor="#F3F3F3" display="flex">
        {itemsToRender.buttons.map((button, index) => {
          return (
            <FrameButton
              key={index}
              label={button.label}
              onClick={button.onClick}
              externalLink={button.externalLink}
            />
          );
        })}
      </Box>
    </Box>
  );
};

interface IFrameButtonProps {
  label: string;
  onClick: () => void;
  externalLink?: boolean;
}
const FrameButton: FC<IFrameButtonProps> = ({
  label,
  onClick,
  externalLink,
}) => {
  return (
    <Box
      component="button"
      onClick={onClick}
      display="flex"
      bgcolor={theme.palette.customColors.WHITE}
      flex={1}
      justifyContent={"center"}
      alignItems={"center"}
      py={1}
      gap={0.5}
      border="1px solid #E0E0E0"
      height={{ xs: 36, sm: 40 }}
      borderRadius={2}
      sx={{
        cursor: "pointer",
      }}
    >
      <Typography
        sx={{
          fontSize: { xs: 12 },
          lineHeight: "20px",
          color: "#2A2A2A",
          fontFamily: "Roboto",
          fontWeight: 400,
        }}
      >
        {label}
      </Typography>
      {externalLink && (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="17"
          height="16"
          viewBox="0 0 17 16"
          fill="none"
        >
          <path
            d="M7.41602 4.00008H4.74935C4.01297 4.00008 3.41602 4.59703 3.41602 5.33341V12.0001C3.41602 12.7365 4.01297 13.3334 4.74935 13.3334H11.416C12.1524 13.3334 12.7493 12.7365 12.7493 12.0001V9.33341M10.0827 2.66675H14.0827M14.0827 2.66675V6.66675M14.0827 2.66675L7.41602 9.33341"
            stroke="#6E62A6"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      )}
    </Box>
  );
};

import { IFontMetric } from "@sdl-marketing/mediautils/interfaces/IFontMetric";
import { EFontWeight } from "../enums/EFontWeight";
import { EFontFamily } from "../enums/EFontFamily";


type FontMetricsWithVariants = {
	[key in EFontWeight]?: IFontMetric;
} & { [EFontWeight.Medium]: IFontMetric };

type FontMetrics = {
	[key in EFontFamily]: FontMetricsWithVariants;
};

// reference: https://github.com/okanzdmr/font-metrics
export const fontMetrics: FontMetrics = {
	[EFontFamily.ChakraPetch]: {
		[EFontWeight.Normal]: {
			widths: [
				0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
				0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0.244, 0.244, 0.34, 0.687,
				0.613, 0.826, 0.638, 0.196, 0.309, 0.309, 0.34, 0.54, 0.206,
				0.402, 0.206, 0.465, 0.628, 0.358, 0.55, 0.579, 0.555, 0.582,
				0.598, 0.498, 0.614, 0.603, 0.206, 0.206, 0.59, 0.54, 0.59,
				0.532, 0.811, 0.62, 0.646, 0.631, 0.664, 0.582, 0.552, 0.652,
				0.674, 0.246, 0.556, 0.617, 0.537, 0.777, 0.673, 0.676, 0.622,
				0.676, 0.651, 0.613, 0.56, 0.676, 0.626, 0.863, 0.604, 0.592,
				0.568, 0.309, 0.48, 0.309, 0.636, 0.422, 0.24, 0.551, 0.578,
				0.534, 0.58, 0.561, 0.376, 0.58, 0.57, 0.244, 0.259, 0.524,
				0.234, 0.837, 0.568, 0.57, 0.58, 0.573, 0.385, 0.512, 0.39,
				0.567, 0.482, 0.707, 0.488, 0.569, 0.474, 0.344, 0.234, 0.344,
				0.562,
			],
			mean: 0.5150736842105262,
			lineHeight: 1.3,
			ascent: 0.992,
			descent: -0.308,
		},
		[EFontWeight.Medium]: {
			widths: [
				0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
				0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0.253, 0.254, 0.38, 0.697,
				0.621, 0.842, 0.646, 0.216, 0.322, 0.322, 0.343, 0.548, 0.217,
				0.412, 0.217, 0.484, 0.636, 0.366, 0.558, 0.587, 0.563, 0.59,
				0.606, 0.506, 0.622, 0.611, 0.215, 0.215, 0.598, 0.548, 0.598,
				0.542, 0.819, 0.629, 0.654, 0.639, 0.672, 0.59, 0.56, 0.66,
				0.682, 0.256, 0.564, 0.628, 0.545, 0.785, 0.681, 0.684, 0.63,
				0.684, 0.659, 0.621, 0.568, 0.684, 0.634, 0.868, 0.62, 0.606,
				0.576, 0.321, 0.499, 0.321, 0.643, 0.442, 0.24, 0.559, 0.585,
				0.542, 0.587, 0.568, 0.384, 0.587, 0.578, 0.253, 0.268, 0.538,
				0.243, 0.845, 0.576, 0.577, 0.587, 0.58, 0.393, 0.52, 0.398,
				0.575, 0.491, 0.714, 0.501, 0.577, 0.482, 0.355, 0.244, 0.355,
				0.57,
			],
			mean: 0.5245368421052633,
			lineHeight: 1.3,
			ascent: 0.992,
			descent: -0.308,
		},
		[EFontWeight.Bold]: {
			widths: [
				0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
				0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0.272, 0.274, 0.46, 0.717,
				0.637, 0.875, 0.662, 0.256, 0.348, 0.348, 0.349, 0.564, 0.238,
				0.432, 0.238, 0.522, 0.652, 0.382, 0.574, 0.603, 0.579, 0.606,
				0.622, 0.522, 0.638, 0.627, 0.232, 0.232, 0.614, 0.564, 0.614,
				0.562, 0.835, 0.646, 0.67, 0.655, 0.688, 0.606, 0.576, 0.676,
				0.698, 0.276, 0.58, 0.651, 0.561, 0.801, 0.697, 0.7, 0.646, 0.7,
				0.675, 0.637, 0.584, 0.7, 0.65, 0.879, 0.652, 0.634, 0.592,
				0.344, 0.537, 0.344, 0.657, 0.482, 0.24, 0.575, 0.6, 0.558,
				0.602, 0.583, 0.4, 0.602, 0.594, 0.272, 0.287, 0.567, 0.262,
				0.861, 0.592, 0.592, 0.602, 0.595, 0.409, 0.536, 0.414, 0.591,
				0.51, 0.729, 0.528, 0.593, 0.498, 0.377, 0.264, 0.377, 0.586,
			],
			mean: 0.5435789473684209,
			lineHeight: 1.3,
			ascent: 0.992,
			descent: -0.308,
		},
	},
	[EFontFamily.Roboto]: {
		[EFontWeight.Normal]: {
			widths: [
				0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
				0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0.24755859375, 0.25732421875,
				0.31982421875, 0.61572265625, 0.5615234375, 0.732421875,
				0.62158203125, 0.17431640625, 0.341796875, 0.34765625,
				0.4306640625, 0.56689453125, 0.1962890625, 0.27587890625,
				0.26318359375, 0.412109375, 0.5615234375, 0.5615234375,
				0.5615234375, 0.5615234375, 0.5615234375, 0.5615234375,
				0.5615234375, 0.5615234375, 0.5615234375, 0.5615234375,
				0.2421875, 0.21142578125, 0.50830078125, 0.548828125,
				0.5224609375, 0.47216796875, 0.89794921875, 0.65234375,
				0.62255859375, 0.65087890625, 0.65576171875, 0.568359375,
				0.552734375, 0.68115234375, 0.712890625, 0.27197265625,
				0.5517578125, 0.626953125, 0.5380859375, 0.873046875,
				0.712890625, 0.6875, 0.630859375, 0.6875, 0.61572265625,
				0.59326171875, 0.5966796875, 0.6484375, 0.63623046875,
				0.88720703125, 0.626953125, 0.6005859375, 0.5986328125,
				0.26513671875, 0.41015625, 0.26513671875, 0.41796875,
				0.451171875, 0.30908203125, 0.5439453125, 0.56103515625,
				0.5234375, 0.56396484375, 0.52978515625, 0.34716796875,
				0.56103515625, 0.55078125, 0.24267578125, 0.23876953125,
				0.5068359375, 0.24267578125, 0.87646484375, 0.5517578125,
				0.5703125, 0.56103515625, 0.568359375, 0.33837890625, 0.515625,
				0.32666015625, 0.55126953125, 0.484375, 0.75146484375,
				0.49560546875, 0.47314453125, 0.49560546875, 0.33837890625,
				0.24365234375, 0.33837890625, 0.68017578125,
			],
			mean: 0.5111122532894737,
			lineHeight: 1.171875,
			ascent: 0.927734375,
			descent: -0.244140625,
		},
		[EFontWeight.Medium]: {
			widths: [
				0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
				0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0.2490234375, 0.26806640625,
				0.32421875, 0.6103515625, 0.568359375, 0.734375, 0.63916015625,
				0.1689453125, 0.3486328125, 0.3525390625, 0.44189453125,
				0.55712890625, 0.2197265625, 0.328125, 0.279296875,
				0.3955078125, 0.568359375, 0.568359375, 0.568359375,
				0.568359375, 0.568359375, 0.568359375, 0.568359375, 0.568359375,
				0.568359375, 0.568359375, 0.26513671875, 0.23779296875,
				0.50830078125, 0.5595703125, 0.5205078125, 0.486328125,
				0.89453125, 0.66552734375, 0.630859375, 0.65283203125,
				0.6533203125, 0.5654296875, 0.54931640625, 0.6806640625,
				0.71044921875, 0.2822265625, 0.55517578125, 0.63037109375,
				0.541015625, 0.87548828125, 0.7099609375, 0.6904296875,
				0.63916015625, 0.6904296875, 0.6240234375, 0.603515625,
				0.60693359375, 0.65185546875, 0.64697265625, 0.8798828125,
				0.6328125, 0.609375, 0.60205078125, 0.27392578125, 0.41796875,
				0.27392578125, 0.42724609375, 0.451171875, 0.322265625,
				0.541015625, 0.56298828125, 0.5234375, 0.564453125,
				0.53662109375, 0.3544921875, 0.56689453125, 0.55517578125,
				0.25537109375, 0.25048828125, 0.52197265625, 0.25537109375,
				0.8701171875, 0.55615234375, 0.5693359375, 0.56298828125,
				0.56787109375, 0.3515625, 0.51611328125, 0.33251953125,
				0.5556640625, 0.49462890625, 0.7431640625, 0.5029296875,
				0.48681640625, 0.5029296875, 0.33544921875, 0.2509765625,
				0.33544921875, 0.66455078125,
			],
			mean: 0.5165810032894737,
			lineHeight: 1.171875,
			ascent: 0.927734375,
			descent: -0.244140625,
		},
		[EFontWeight.Bold]: {
			widths: [
				0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
				0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0.2490234375, 0.27197265625,
				0.32080078125, 0.595703125, 0.57373046875, 0.73828125, 0.65625,
				0.16162109375, 0.35107421875, 0.3525390625, 0.453125,
				0.5458984375, 0.244140625, 0.3876953125, 0.29052734375,
				0.37353515625, 0.57373046875, 0.57373046875, 0.57373046875,
				0.57373046875, 0.57373046875, 0.57373046875, 0.57373046875,
				0.57373046875, 0.57373046875, 0.57373046875, 0.2822265625,
				0.26220703125, 0.5087890625, 0.572265625, 0.5166015625,
				0.49755859375, 0.89501953125, 0.6728515625, 0.63818359375,
				0.654296875, 0.64990234375, 0.5625, 0.5478515625, 0.68115234375,
				0.70654296875, 0.29150390625, 0.55859375, 0.634765625,
				0.54150390625, 0.8759765625, 0.7060546875, 0.6904296875,
				0.64501953125, 0.6904296875, 0.63818359375, 0.61474609375,
				0.61865234375, 0.658203125, 0.65380859375, 0.87451171875,
				0.63525390625, 0.6181640625, 0.60595703125, 0.27783203125,
				0.421875, 0.27783203125, 0.43701171875, 0.4462890625,
				0.33056640625, 0.5361328125, 0.56298828125, 0.521484375,
				0.5634765625, 0.54052734375, 0.3583984375, 0.57080078125,
				0.5595703125, 0.26513671875, 0.259765625, 0.5341796875,
				0.26513671875, 0.86572265625, 0.56005859375, 0.5654296875,
				0.56298828125, 0.56494140625, 0.36474609375, 0.51416015625,
				0.337890625, 0.5595703125, 0.50537109375, 0.73486328125,
				0.5087890625, 0.501953125, 0.5087890625, 0.330078125,
				0.2529296875, 0.330078125, 0.6484375,
			],
			mean: 0.5205129523026316,
			lineHeight: 1.171875,
			ascent: 0.927734375,
			descent: -0.244140625,
		},
	},
	[EFontFamily.Inter]: {
		[EFontWeight.Normal]: {
			widths: [
				0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
				0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0.28125, 0.2784090909090909,
				0.4034090909090909, 0.6306818181818182, 0.6377840909090909,
				0.8125, 0.6392045454545454, 0.2215909090909091,
				0.3622159090909091, 0.3622159090909091, 0.5, 0.6590909090909091,
				0.27982954545454547, 0.4602272727272727, 0.2755681818181818,
				0.3565340909090909, 0.625, 0.46448863636363635,
				0.6051136363636364, 0.6363636363636364, 0.6420454545454546,
				0.6079545454545454, 0.6235795454545454, 0.5710227272727273,
				0.6164772727272727, 0.6235795454545454, 0.2755681818181818,
				0.27982954545454547, 0.6590909090909091, 0.6590909090909091,
				0.6590909090909091, 0.5071022727272727, 0.9360795454545454,
				0.6761363636363636, 0.6505681818181818, 0.7272727272727273,
				0.71875, 0.5980113636363636, 0.5866477272727273,
				0.7428977272727273, 0.7400568181818182, 0.26420454545454547,
				0.5426136363636364, 0.6519886363636364, 0.5625,
				0.8892045454545454, 0.7528409090909091, 0.7613636363636364,
				0.6349431818181818, 0.7613636363636364, 0.6392045454545454,
				0.6377840909090909, 0.6420454545454546, 0.7414772727272727,
				0.6761363636363636, 0.9488636363636364, 0.6420454545454546,
				0.6647727272727273, 0.625, 0.3622159090909091,
				0.3565340909090909, 0.3622159090909091, 0.46875,
				0.45170454545454547, 0.4971590909090909, 0.5639204545454546,
				0.6207386363636364, 0.5582386363636364, 0.6207386363636364,
				0.5823863636363636, 0.36079545454545453, 0.609375,
				0.5909090909090909, 0.2372159090909091, 0.2372159090909091,
				0.5440340909090909, 0.2372159090909091, 0.8693181818181818,
				0.5852272727272727, 0.5965909090909091, 0.609375, 0.609375,
				0.3721590909090909, 0.5227272727272727, 0.36363636363636365,
				0.5809659090909091, 0.5568181818181818, 0.8125,
				0.5397727272727273, 0.5568181818181818, 0.5411931818181818,
				0.3622159090909091, 0.32670454545454547, 0.3622159090909091,
				0.6590909090909091,
			],
			mean: 0.5549342105263155,
			lineHeight: 1.2102272727272727,
			ascent: 0.96875,
			descent: -0.24147727272727273,
		},
		[EFontWeight.Medium]: {
			widths: [
				0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
				0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0.2649147727272727,
				0.2936789772727273, 0.39985795454545453, 0.6370738636363636,
				0.6434659090909091, 0.828125, 0.6509232954545454,
				0.21732954545454544, 0.3778409090909091, 0.3778409090909091,
				0.5216619318181818, 0.6658380681818182, 0.28764204545454547,
				0.4630681818181818, 0.2830255681818182, 0.36754261363636365,
				0.6459517045454546, 0.47265625, 0.61328125, 0.6441761363636364,
				0.6541193181818182, 0.6200284090909091, 0.6356534090909091,
				0.5788352272727273, 0.6313920454545454, 0.6356534090909091,
				0.2830255681818182, 0.28764204545454547, 0.6658380681818182,
				0.6658380681818182, 0.6658380681818182, 0.5255681818181818,
				0.9676846590909091, 0.6999289772727273, 0.6541193181818182,
				0.7354403409090909, 0.7215909090909091, 0.6029829545454546,
				0.5859375, 0.7489346590909091, 0.7421875, 0.26953125,
				0.5518465909090909, 0.6644176136363636, 0.5642755681818182,
				0.8977272727272727, 0.7468039772727273, 0.7681107954545454,
				0.6392045454545454, 0.7684659090909091, 0.6448863636363636,
				0.6434659090909091, 0.6509232954545454, 0.7375710227272727,
				0.6999289772727273, 0.9779829545454546, 0.6661931818181818,
				0.6846590909090909, 0.6388494318181818, 0.3778409090909091,
				0.36754261363636365, 0.3778409090909091, 0.47514204545454547,
				0.4598721590909091, 0.4971590909090909, 0.5692471590909091,
				0.6257102272727273, 0.5678267045454546, 0.6257102272727273,
				0.5873579545454546, 0.3689630681818182, 0.6171875,
				0.6019176136363636, 0.2489346590909091, 0.2489346590909091,
				0.5564630681818182, 0.2489346590909091, 0.8835227272727273,
				0.5973011363636364, 0.6022727272727273, 0.6171875, 0.6171875,
				0.38423295454545453, 0.5358664772727273, 0.3718039772727273,
				0.5944602272727273, 0.56640625, 0.8249289772727273,
				0.5511363636363636, 0.56640625, 0.5514914772727273,
				0.3778409090909091, 0.34232954545454547, 0.3778409090909091,
				0.6658380681818182,
			],
			mean: 0.5645222787081338,
			lineHeight: 1.2102272727272727,
			ascent: 0.96875,
			descent: -0.24147727272727273,
		},
		[EFontWeight.Bold]: {
			widths: [
				0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
				0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0.23188920454545456,
				0.32457386363636365, 0.3924005681818182, 0.6495028409090909,
				0.6548295454545454, 0.859375, 0.6740056818181818,
				0.20880681818181818, 0.4090909090909091, 0.4090909090909091,
				0.5646306818181818, 0.6796875, 0.3029119318181818, 0.46875,
				0.2975852272727273, 0.3899147727272727, 0.6882102272727273,
				0.4893465909090909, 0.6299715909090909, 0.6594460227272727,
				0.6779119318181818, 0.64453125, 0.66015625, 0.5948153409090909,
				0.6608664772727273, 0.66015625, 0.2975852272727273,
				0.3029119318181818, 0.6796875, 0.6796875, 0.6796875, 0.5625,
				1.0305397727272727, 0.7478693181818182, 0.6608664772727273,
				0.7521306818181818, 0.7272727272727273, 0.6125710227272727,
				0.5848721590909091, 0.7606534090909091, 0.74609375,
				0.2805397727272727, 0.5699573863636364, 0.6896306818181818,
				0.5674715909090909, 0.9147727272727273, 0.7350852272727273,
				0.7819602272727273, 0.6477272727272727, 0.7826704545454546,
				0.65625, 0.6548295454545454, 0.6683238636363636,
				0.7294034090909091, 0.7478693181818182, 1.0358664772727273,
				0.7144886363636364, 0.7244318181818182, 0.6669034090909091,
				0.4090909090909091, 0.3899147727272727, 0.4090909090909091,
				0.4875710227272727, 0.4765625, 0.4971590909090909,
				0.5802556818181818, 0.6352982954545454, 0.5873579545454546,
				0.6352982954545454, 0.59765625, 0.3856534090909091,
				0.6331676136363636, 0.6242897727272727, 0.27201704545454547,
				0.27201704545454547, 0.5816761363636364, 0.27201704545454547,
				0.9119318181818182, 0.6218039772727273, 0.6136363636363636,
				0.6324573863636364, 0.6324573863636364, 0.40873579545454547,
				0.5617897727272727, 0.3884943181818182, 0.6218039772727273,
				0.5859375, 0.8501420454545454, 0.5738636363636364, 0.5859375,
				0.5717329545454546, 0.4090909090909091, 0.37357954545454547,
				0.4090909090909091, 0.6796875,
			],
			mean: 0.5837245813397128,
			lineHeight: 1.2102272727272727,
			ascent: 0.96875,
			descent: -0.24147727272727273,
		},
	},
};

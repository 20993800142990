import "reflect-metadata";

import { ConfigProvider } from "./utilities/ConfigProvider";
import { AdRepository } from "./data/AdRepository";
import { AdService } from "./business/AdService";
import { PublicGatewayRepository } from "./data/PublicGatewayRepository";
import { AxiosAjaxUtils } from "./utilities/AxiosAjaxUtils";
import { FetchAdapter } from "./utilities/FetchAdapter";
import { IApiGateway } from "@sdl-marketing/objects/interfaces/IApiGateway";
import { ConsumerPortalConfig } from "@sdl-marketing/objects/primitives/ConsumerPortalConfig";
import { IFetchAdapter } from "@sdl-marketing/objects/interfaces/IFetchAdapter";
import { IAdService } from "@sdl-marketing/objects/interfaces/business/IAdService";
import { IAdRepository } from "@sdl-marketing/objects/interfaces/data/IAdRepository";

export class ApiGateway implements IApiGateway {
  public config: ConsumerPortalConfig;
  public ajaxUtils: IFetchAdapter;
  public adService: IAdService;

  private adRepository: IAdRepository;

  constructor() {
    this.ajaxUtils = new FetchAdapter(
      /* "https://public-api.dev.snickerdoodle.dev/" */
      "https://public-api.snickerdoodle.com"
    );
    const apiGatewayConfigProvider = new ConfigProvider();
    const publicGatewayRepository = new PublicGatewayRepository(this.ajaxUtils);
    this.config = apiGatewayConfigProvider.getConfig();
    this.adRepository = new AdRepository(
      this.config,
      this.ajaxUtils,
      publicGatewayRepository
    );
    this.adService = new AdService(this.adRepository);
  }
}


import React, { FC, useCallback, useEffect, useMemo } from "react";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import WarpcastIcon from "@sdl-marketing/assets/image/icons/warpcast.png";
import LinkIcon from "@sdl-marketing/assets/image/icons/attachment.png";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import { Tooltip } from "@mui/material";
import { IAdDetailProps } from "./AdDetail.interface";
import { useCopyToClipboard } from "@sdl-marketing/hooks/useCopyToClipboard";
import theme from "@sdl-marketing/theme/theme";
import { useStoreContext } from "@sdl-marketing/contexts/StoreContext";
import { useLayoutContext } from "@sdl-marketing/contexts/LayoutContext";
import { FramePreview } from "../framepreview/FramePreview";
import { ModalButton } from "../buttons/ModalButton";
import { formatPrice, getName } from "@sdl-marketing/utils/FrameUtils";
import CloseIcon from "@mui/icons-material/Close";
import { Tabs } from "../tabs/Tabs";

const TabItems = [{ label: "Share" }, { label: "Preview" }];

const FARCASTER_DEEPLINK_BASE = "https://warpcast.com/~/compose?embeds[]=";
const REST_API_PREFIX = "/v0";

interface ILinkDisplayItemProps {
  linkText: string;
}

const LinkDisplayItem: FC<ILinkDisplayItemProps> = ({ linkText }) => {
  const { copied, copy } = useCopyToClipboard();
  const [tooltipOpen, setTooltipOpen] = React.useState(false);
  const textRef = React.useRef<HTMLParagraphElement>(null);

  const isTruncated = useCallback(() => {
    return (
      (textRef.current?.offsetWidth || 0) < (textRef.current?.scrollWidth || 0)
    );
  }, []);

  useEffect(() => {
    isTruncated();
  }, [linkText]);
  return (
    <Box
      borderRadius={3}
      justifyContent="space-between"
      gap={1.5}
      border={`1px solid ${theme.palette.customColors.DARKPURPLE100}`}
      display="flex"
      alignItems="center"
      minWidth={0}
      px={2}
      height={40}
      flex={1}
    >
      <Box
        sx={{
          overflow: "hidden",
          textOverflow: "ellipsis",
          whiteSpace: "nowrap",
        }}
      >
        <Tooltip
          componentsProps={{
            tooltip: {
              sx: {
                backgroundColor: theme.palette.customColors.MAINPURPLE50,
              },
            },
          }}
          open={tooltipOpen}
          title={
            <Typography
              sx={{
                color: theme.palette.customColors.DARKPURPLE500,
                fontFamily: "DM Sans",
                fontSize: { xs: 12 },
                fontWeight: 400,
              }}
            >
              {linkText}
            </Typography>
          }
        >
          <Typography
            onMouseLeave={() => {
              setTooltipOpen(false);
            }}
            onMouseEnter={() => {
              isTruncated() && setTooltipOpen(true);
            }}
            ref={textRef}
            noWrap
            sx={{
              fontSize: { xs: 16 },
              fontFamily: "Dm Sans",
              color: theme.palette.customColors.DARKPURPLE500,
            }}
          >
            {linkText}
          </Typography>
        </Tooltip>
      </Box>
      <Tooltip
        componentsProps={{
          tooltip: {
            sx: {
              backgroundColor: theme.palette.customColors.MAINPURPLE50,
            },
          },
        }}
        open={copied}
        title={
          <Typography
            sx={{
              color: theme.palette.customColors.DARKPURPLE500,
              fontFamily: "DM Sans",
              fontSize: { xs: 12 },
              fontWeight: 400,
            }}
          >
            Link copied to clipboard
          </Typography>
        }
      >
        <ContentCopyIcon
          sx={{
            cursor: "pointer",
          }}
          onClick={() => {
            copy(linkText);
          }}
        />
      </Tooltip>
    </Box>
  );
};

const AdDetail: FC = () => {
  const { apiGateway } = useStoreContext();
  const [selectedTab, setSelectedTab] = React.useState(0);
  const { modalState, closeModal } = useLayoutContext();
  const { ad } = modalState.customProps as IAdDetailProps;
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));

  const content = useMemo(() => {
    switch (selectedTab) {
      case 1: {
        return (
          <FramePreview
            onPreviewComplete={() => {
              setSelectedTab(0);
            }}
            ad={ad}
          />
        );
      }
      case 0: {
        return (
          <>
            <Box mb={5.5}>
              <Box gap={2} display="flex" mb={3} alignItems="center">
                <Box component="img" src={LinkIcon} width={32} height={32} />
                <Typography
                  sx={{
                    fontSize: { xs: 18, sm: 22 },
                    fontWeight: 700,
                    color: theme.palette.customColors.DARKPURPLE500,
                  }}
                >
                  Frame Link
                </Typography>
              </Box>

              <LinkDisplayItem
                linkText={`${apiGateway.config.farcasterBaseUrl}${REST_API_PREFIX}/${ad.id}`}
              />
            </Box>
            <Box mb={{ xs: "none", sm: 15 }}>
              <Box gap={2} display="flex" mb={3} alignItems="center">
                <Box
                  component="img"
                  src={WarpcastIcon}
                  width={32}
                  height={32}
                />
                <Typography
                  sx={{
                    fontSize: { xs: 18, sm: 22 },
                    fontWeight: 700,
                    color: theme.palette.customColors.DARKPURPLE500,
                  }}
                >
                  Warpcast
                </Typography>
              </Box>
              <Box display="flex" alignItems="center" gap={2}>
                <LinkDisplayItem
                  linkText={`${apiGateway.config.farcasterBaseUrl}${REST_API_PREFIX}/${ad.id}`}
                />
                <ModalButton
                  size="small"
                  variant="main"
                  onClick={() => {
                    window.open(
                      `${FARCASTER_DEEPLINK_BASE}${apiGateway.config.farcasterBaseUrl}${REST_API_PREFIX}/${ad.id}`,
                      "_blank"
                    );
                  }}
                >
                  Cast
                </ModalButton>
              </Box>
            </Box>
          </>
        );
      }
      default: {
        return;
      }
    }
  }, [selectedTab, ad]);

  return (
    <Dialog
      open
      onClose={() => {
        closeModal(modalState.modalSelector);
      }}
      fullWidth
      fullScreen={fullScreen}
      sx={{
        "& .MuiDialog-paper": {
          borderRadius: { xs: 0, sm: 5 },
          p: 0,
          maxWidth: {
            xs: "100%",
            sm: 662,
          },
        },
        "& .MuiDialog-container": {},
      }}
    >
      <DialogTitle
        sx={{
          px: { xs: 2, sm: 4 },
          py: { xs: 2, sm: 3 },
          borderBottom: `1px solid ${theme.palette.customColors.DARKPURPLE100}`,
        }}
      >
        <Box display="flex" justifyContent="space-between" alignItems="center">
          <Typography
            sx={{
              fontFamily: "Shrikhand",
              fontSize: { xs: 24 },
              fontWeight: 400,
              color: theme.palette.customColors.DARKPURPLE500,
            }}
          >
            Share
          </Typography>
          <CloseIcon
            fontSize="large"
            onClick={() => {
              closeModal(modalState.modalSelector);
            }}
            sx={{
              color: theme.palette.customColors.DARKPURPLE300,
              cursor: "pointer",
            }}
          />
        </Box>
      </DialogTitle>
      <DialogContent
        sx={{
          px: { xs: 3, sm: 7 },
        }}
      >
        <Box display="flex" flexDirection="column" my={{ xs: 3, sm: 4 }}>
          <Tabs
            tabs={TabItems}
            activeIndex={selectedTab}
            onTabClick={(index) => setSelectedTab(index)}
          />
        </Box>
        {content}
      </DialogContent>
      <DialogActions
        disableSpacing
        sx={{
          borderTop: `1px solid ${theme.palette.customColors.DARKPURPLE100}`,
          py: { xs: 2 },
          justifyContent: "center",
          gap: 0,
        }}
      >
        <Box display="flex" justifyContent="flex-end" flex={1}>
          <Box
            display="flex"
            flexDirection="column"
            alignItems="center"
            px={5.5}
            sx={{
              borderRight: `0.5px solid ${theme.palette.customColors.DARKPURPLE100}`,
            }}
          >
            <Typography
              align="center"
              mb={0.5}
              sx={{
                fontSize: { xs: 14 },
                fontWeight: 500,
                color: theme.palette.customColors.MINT700,
                opacity: 0.56,
              }}
            >
              You Earn
            </Typography>
            <Typography
              align="center"
              sx={{
                fontSize: { xs: 16 },
                fontWeight: 700,
                color: theme.palette.customColors.MINT500,
              }}
            >
              {`${formatPrice(ad.casterViewPrice)} ${getName(
                ad.reward.chainId
              )}`}
            </Typography>
          </Box>
        </Box>
        <Box display="flex" justifyContent="flex-start" flex={1}>
          <Box
            display="flex"
            flexDirection="column"
            alignItems="center"
            px={5.5}
            sx={{
              borderLeft: `0.5px solid ${theme.palette.customColors.DARKPURPLE100}`,
            }}
          >
            <Typography
              align="center"
              mb={0.5}
              sx={{
                fontSize: { xs: 14 },
                fontWeight: 500,
                color: theme.palette.customColors.MINT700,
                opacity: 0.56,
              }}
            >
              Viewer Earns
            </Typography>
            <Typography
              align="center"
              sx={{
                fontSize: { xs: 16 },
                fontWeight: 700,
                color: theme.palette.customColors.MINT500,
              }}
            >
              {`${formatPrice(ad.interactorViewPrice)} ${getName(
                ad.reward.chainId
              )}`}
            </Typography>
          </Box>
        </Box>
      </DialogActions>
    </Dialog>
  );
};

export default AdDetail;

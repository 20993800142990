import { fontMetrics } from "@sdl-marketing/objects/constants/FontMetrics";
import { EFontFamily } from "@sdl-marketing/objects/enums/EFontFamily";
import { EFontWeight } from "@sdl-marketing/objects/enums/EFontWeight";


interface ILine {
	content: string;
	width: number;
}

export class TextUtils {
	public static getWrappedTextWithInfo(
		text: string,
		maxWidth: number,
		fontSize: number,
		fontFamily: EFontFamily,
		fontWeight: EFontWeight,
	): {
		lines: ILine[];
		totalHeight: number;
		lineHeight: number;
		totalWidth: number;
		ascent: number;
	} {
		// Get the metrics for the font family and weight
		// Use fallback to medium if the metrics are not available --Medium is required in the interface
		const metrics =
			fontMetrics[fontFamily][fontWeight] ??
			fontMetrics[fontFamily][EFontWeight.Medium];

		const { widths, mean, lineHeight, ascent } = metrics;

		const spaceWidth = widths[" ".charCodeAt(0)] * fontSize;

		// Split content into words
		const words = text.split(" ");

		const lines: { content: string; width: number }[] = [];

		// Initialize variables for current line and width
		let currentLine = "";
		let currentWidth = 0;

		for (const word of words) {
			// Calculate the width of the word
			const wordWidth = word
				.split("")
				.map((char) => (widths[char.charCodeAt(0)] ?? mean) * fontSize)
				.reduce((acc, width) => acc + width, 0);

			// If the word doesn't fit on the current line, start a new line
			if (currentWidth + wordWidth + spaceWidth > maxWidth) {
				// Add the current line to the lines array
				if (currentLine) {
					lines.push({ content: currentLine, width: currentWidth });
				}
				// Start a new line with the current word
				currentLine = word;
				currentWidth = wordWidth;
			} else {
				// Add the word to the current line
				currentWidth += wordWidth + (currentLine ? spaceWidth : 0);
				currentLine += (currentLine ? " " : "") + word;
			}
		}

		// Add the last line to the lines array
		if (currentLine) {
			lines.push({ content: currentLine, width: currentWidth });
		}

		// Calculate the total height of the text
		const totalHeight = lines.length * lineHeight * fontSize;
		// Calculate the total width of the text
		const totalWidth = Math.max(...lines.map((line) => line.width));

		return { lines, totalHeight, lineHeight, totalWidth, ascent };
	}
}

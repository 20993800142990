import React, { Suspense } from "react";
import { RouteObject, Navigate } from "react-router-dom";
import { ERoutes } from "@sdl-marketing/objects/enums/ERoutes";

const MainLayout = React.lazy(() => import("@sdl-marketing/layout/MainLayout"));
const LandingPage = React.lazy(
  () => import("@sdl-marketing/pages/landingpage/LandingPage")
);
const SpacesPage = React.lazy(
  () => import("@sdl-marketing/pages/spacespage/SpacesPage")
);
const FeaturesPage = React.lazy(
  () => import("@sdl-marketing/pages/featurespage/FeaturesPage")
);
const BlogPage = React.lazy(
  () => import("@sdl-marketing/pages/blogpage/BlogPage")
);
const EventsPage = React.lazy(
  () => import("@sdl-marketing/pages/eventspage/EventsPage")
);
const ProductPage = React.lazy(
  () => import("@sdl-marketing/pages/productpage/ProductPage")
);
const ProductDetail = React.lazy(
  () => import("@sdl-marketing/pages/productpage/sections/ProductDetail")
);
const BlogPageDetails = React.lazy(
  () => import("@sdl-marketing/pages/blogpage/section/BlogPageDetails")
);

const FeatureDetails = React.lazy(
  () => import("@sdl-marketing/pages/featurespage/section/FeatureDetails")
);

const PrivacyPolicy = React.lazy(
  () => import("@sdl-marketing/pages/rulespage/PrivacyPolicy")
);

const TermsConditions = React.lazy(
  () => import("@sdl-marketing/pages/rulespage/TermsConditions")
);

const StateOfFarcasterPage = React.lazy(
  () => import("@sdl-marketing/pages/stateoffarcasterpage/StateOfFarcasterPage")
);

const Loader = React.lazy(
  () => import("@sdl-marketing/components/loader/Loader")
);

const CommonLayoutRoutes: RouteObject = {
  path: "/",
  element: (
    <Suspense fallback={<Loader />}>
      <MainLayout />
    </Suspense>
  ),
  children: [
    {
      path: ERoutes.LANDING_PAGE,
      element: (
        <Suspense fallback={<Loader />}>
          <LandingPage />
        </Suspense>
      ),
    },
    {
      path: ERoutes.STATE_OF_FARCASTER_PAGE,
      element: (
        <Suspense fallback={<Loader />}>
          <StateOfFarcasterPage />
        </Suspense>
      ),
    },
    {
      path: ERoutes.SPACES_PAGE,
      element: (
        <Suspense fallback={<Loader />}>
          <SpacesPage />
        </Suspense>
      ),
    },
    {
      path: ERoutes.FEATURES_PAGE,
      element: (
        <Suspense fallback={<Loader />}>
          <FeaturesPage />
        </Suspense>
      ),
    },
    {
      path: `${ERoutes.FEATURES_PAGE}/:featureId`,
      element: (
        <Suspense fallback={<Loader />}>
          <FeatureDetails />
        </Suspense>
      ),
    },
    {
      path: ERoutes.BLOG_PAGE,
      element: (
        <Suspense fallback={<Loader />}>
          <BlogPage />
        </Suspense>
      ),
    },
    {
      path: ERoutes.EVENTS_PAGE,
      element: (
        <Suspense fallback={<Loader />}>
          <EventsPage />
        </Suspense>
      ),
    },
    {
      path: ERoutes.PRODUCT_PAGE,
      element: (
        <Suspense fallback={<Loader />}>
          <ProductPage />
        </Suspense>
      ),
    },
    {
      path: `${ERoutes.PRODUCT_PAGE}/:productType`,
      element: (
        <Suspense fallback={<Loader />}>
          <ProductDetail />
        </Suspense>
      ),
    },
    {
      path: `${ERoutes.BLOG_PAGE}/:blogTitle/:blogID`,
      element: (
        <Suspense fallback={<Loader />}>
          <BlogPageDetails />
        </Suspense>
      ),
    },
    {
      path: ERoutes.TERMS_AND_CONDITIONS_PAGE,
      element: (
        <Suspense fallback={<Loader />}>
          <TermsConditions />
        </Suspense>
      ),
    },
    {
      path: ERoutes.PRIVACY_POLICY_PAGE,
      element: (
        <Suspense fallback={<Loader />}>
          <PrivacyPolicy />
        </Suspense>
      ),
    },
    {
      path: ERoutes.NOMATCH,
      element: <Navigate to="/" replace />,
    },
  ],
};

const CommonRoutes: RouteObject[] = [CommonLayoutRoutes];

export default CommonRoutes;

export enum EQuestionnaireStatus {
  Available = 0,
  Complete = 1,
}
export enum EQuestionnaireQuestionType {
  MultipleChoice = "MultipleChoice",
  Location = "Location",
  Text = "Text",
  Numeric = "Numeric",
}
export enum EQuestionnaireQuestionDisplayType {
  Dropdown = "Dropdown",
  List = "List",
  Scale = "Scale",
}

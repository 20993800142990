
import { BigNumberString } from "@sdl-marketing/objects/primitives/BigNumberString";
import { ChainId } from "@sdl-marketing/objects/primitives/ChainId";
import { formatUnits } from "ethers";

// hardcoding the name for now
export const getName = (chainId: ChainId) => {
	switch (chainId) {
		case ChainId(43113):
		case ChainId(43114):
			return "$COQINU";
		default:
			return "$DEGEN";
	}
};

export const formatPrice = (value: BigNumberString) => {
	const formatted = formatUnits(value, 18);

	const trailingZeroRegex = /(\.\d*[1-9])0+|\.0+$/;
	const result = formatted.replace(trailingZeroRegex, "$1");

	return result.endsWith(".") ? result.slice(0, -1) : result;
};